import React from "react";
import { Image } from "react-native";
import use from "rn-tools/hook";

function AppName({ ...props }) {
	props.style = use.style(props.style, localStyles.layout);

	return (
		<Image  
			source="/logo.svg"
			resizeMode="contain"
			{...props}
		/>
	);
}

export default React.memo(AppName);

const localStyles = {
	layout: {
		height: 25,
		width: 115,
	},
};