import is from 'js-tools/is';
import React from 'react'
import { Text } from "react-native"
import use from "rn-tools/hook"


export default function TextHolder({ for: holder, ...props }) {
	if (!is.defined(props.children)) {
		if (typeof holder === 'number')
			props.children = new Array(holder)
				.fill('0')
				.join('');
		else
			props.children = holder;
	}

	props.style = use.style(props.style, { opacity: 0 });

	return <Text {...props} />;
}