import React from "react"
import RNText from "rn-tools/component/Text"
import use from "rn-tools/hook";

export default function Text({...props}){
	props.style = use.style(props.style, STYLE);
	return <RNText {...props} />;
}

const STYLE = {
	color: "white",
};