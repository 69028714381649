import React from "react";
import { View as RNView } from "react-native";
import use from "rn-tools/hook";
import MatchScores from "../../../component/MatchScores";
import { colors, styles } from "../../../res";
import Text from "../../Text";
import { isNil, applyTo, gt, when, lt, complement, ifElse } from 'ramda'

export default function ScoresView({ pronostic, ...props }) {
	const hasScore = isDefined(pronostic?.score);
	const color = hasScore &&
		COLOR_PREDICATES.map(applyTo(pronostic.score)).find(Boolean);

	props.style = use.style(props.style, localStyles.layout);

	return (
		<RNView {...props}>
			<MatchScores scores={pronostic} style={localStyles.scores} />


			{
				Boolean(pronostic?.comment) &&
				<Text
					numberOfLines={2}
					style={localStyles.comment}>
					{pronostic?.comment}
				</Text>
			}

			{
				hasScore &&
				<Text style={[localStyles.score, { color }]}>
					{pronostic.score >= 0 && '+'}
					{pronostic.score}
				</Text>
			}
		</RNView>
	);
}

const COLOR_PREDICATES = [
	ifElse(gt(0), () => 'red', () => undefined),
	ifElse(gt(1), () => 'lightgrey', () => undefined),
	ifElse(lt(1), () => '#2bbd57', () => undefined),
	ifElse(lt(0), () => '#80bd92', () => undefined),
];

const isDefined = complement(isNil);

const localStyles = {
	layout: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 10,
	},

	scores: {
		letterSpacing: 10,
	},

	toggle: [
		styles.absolute({ left: 0, right: 0, bottom: 0 }),
		styles.center,
	],

	comment: {
		maxWidth: '80%',
		textAlign: 'center',
		color: colors.neutral(3),
		marginTop: 7,
	},

	score: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
};