import moment from "moment";
import React from "react";
import { View } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import use from "rn-tools/hook";
import { strings, styles } from "../../res";
import TeamLogo from "../TeamLogo";
import Text from "../Text";
import fr from "ipronostic.common/src/lang/fr"
import { isNil } from "ramda";

export default function MobileMatchUIHeader({ match, ...props }) {
	const time = match?.time && moment(match.time);

	props.style = use.style(props.style, localStyles.layout);

	return (
		<View {...props} >
			<View style={[localStyles.team.layout, { alignItems: 'flex-start' }]}>
				<TeamLogo
					source={match?.team1.logo}
					loading={!match}
					size={60}
					style={{ marginRight: 10 }}
				/>
				<LoadingPlaceholder
					Component={Text}
					animated={!match}
					numberOfLines={1}
					style={[localStyles.team.name, { alignItems: 'flex-start' }]}>
					{fr(match?.team1.name)}
					{strings.invisible}
				</LoadingPlaceholder>
			</View>

			<View style={[localStyles.team.layout, { flexDirection: "row-reverse", alignItems: 'flex-end' }]}>
				<TeamLogo
					source={match?.team2.logo}
					loading={!match}
					size={60}
					style={{ marginLeft: 10 }}
				/>

				<LoadingPlaceholder
					Component={Text}
					animated={!match}
					numberOfLines={1}
					style={[localStyles.team.name, { textAlign: 'right', alignItems: 'flex-end' }]}>
					{fr(match?.team2.name)}
					{strings.invisible}
				</LoadingPlaceholder>
			</View>

			<View style={localStyles.body}>
				<Text style={localStyles.team.score}>
					{match?.score1}
					{!isNil(match?.score1) && ' - '}
					{match?.score2}
				</Text>

				<Text>
					{fr(match?.league)}
				</Text>

				<Text style={localStyles.date(time)}>
					{time?.format('DD/MM/YY')}
				</Text>
			</View>
		</View>
	);
}


const localStyles = {
	layout: {
		paddingHorizontal: 20,
	},

	team: {
		layout: {
			flexDirection: 'row',
			paddingVertical: 10
		},

		name: {
			fontSize: 20,
			minWidth: 100,
			paddingHorizontal: 5,
			borderRadius: 10,
			marginBottom: 5,
			fontWeight: 'bold',
		},

		score: {
			fontSize: 23,
			marginBottom: 3,
			fontFamily: '"Black Ops One", serif',
		}
	},
	body: {
		...styles.absolute({ left: 0, right: 0, top: "50%" }),
		transform: [{ translateY: '-50%' }],
		fontSize: 15,
		textAlign: 'center',
	},

	date: time => ({
		fontSize: 13,
		textAlign: 'center',
		color: time?.isBefore() ? 'grey' : 'white',
	}),
};