import _selectFiles from "select-files";
import { falsy, truthy } from "../@types/utils";


export default function selectFiles(accept?: MimeType, multiple?: falsy): Promise<File | undefined>;
export default function selectFiles<X>(accept: MimeType, multiple: truthy<X>): Promise<File[]>;

export default async function selectFiles(accept: MimeType = MimeType.any, multiple?: any): Promise<File | File[] | undefined> {
	const selection = await _selectFiles({ accept, multiple }) || [];
	return multiple ? Array.from(selection) : selection[0];
}

export enum MimeType {
	any = '*',
	image = 'image/*',
}
