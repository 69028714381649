import { gql } from "@apollo/client";
import { useIsFocused } from "@react-navigation/native";
import AppLogo from "@src/component/AppLogo";
import AppName from "@src/component/AppName";
import screens from "@src/screens";
import { nthArg, pipe } from "ramda";
import React from "react";
import { FlatList } from "react-native";
import Portal from "rn-tools/component/Portal";
import View from "rn-tools/component/View";
import useQueryIterator from "rn-tools/hook/useQueryIterator";
import useQueryLoader from "rn-tools/hook/useQueryLoader";
import useUser from "rn-tools/hook/useUser";
import Text from "../../component/Text";
import ErrorView from "../../component/ErrorView";
import { styles } from "../../res";
import MatchReview from "./MatchReview";
import IPronostic from "../../component/IPronostic";


function MatchesListUI() {
	const user = useUser();
	const variables = { uid: user?.uid };
	const loader = useQueryLoader(user !== undefined && QUERY, variables);
	const iterator = useQueryIterator(loader, {
		retrieveItems: data => data.match,
		range: NUMBER_PER_PAGE,
	});
	const matches = iterator.items;

	const isFocused = useIsFocused();
	const loadNextPage = isFocused ? iterator.willLoadNext({ offset: matches?.length }) : undefined;

	return (
		<FlatList
			ListHeaderComponent={HEADER}
			keyExtractor={keyExtractor}
			data={matches}
			renderItem={({ item }) => (
				<Portal to={screens.match} with={{ id: item?.id }} disabled={!item}>
					<MatchReview item={item} style={localStyles.item} />
				</Portal>
			)}

			onEndReached={loadNextPage}
			onEndReachedThreshold={.5}
			ListFooterComponent={
				loader.error ?
					<ErrorView retry={loader.refetch} /> :

					!iterator.end &&
					<>
						<MatchReview style={localStyles.item} />

						{
							!matches?.length &&
							<MatchReview style={localStyles.item} />
						}
					</>
			}
			contentContainerStyle={localStyles.content}
			style={localStyles.layout}
		/>
	);
}

export default React.memo(MatchesListUI);

const NUMBER_PER_PAGE = 20;

const QUERY = gql`
  query MatchesListUI($offset: Int, $uid: String =  "") {
    match(
		offset: $offset
    	limit: ${NUMBER_PER_PAGE}
    	order_by: { time: asc }
		where: { time: { _gt: "now()" } }
    ) {
      id
      numberOfPronostics
      time
	  league

      team2 {
        name
        logo
      }

      team1 {
        name
        logo
      }

      #pronosticsStats(limit: 3, order_by: { numberOfPronostics: desc }) {
      #  numberOfPronostics
      #  score1
      #  score2
	  #}
	  
	  userPronostics: pronostics(where: {user: {firebaseId: {_eq: $uid}}}) {
		id
		score1
		score2
	  }
    }
  }
`;

const HEADER = (
	<View  >
		<IPronostic style={{
			marginVertical: 150,
			width: '50%',
			maxWidth: 350,
			height: 100,
			alignSelf: 'center'
		}} />

		<Text
			style={{
				paddingBottom: 10,
				textAlign: "center",
				fontWeight: "bold",
				fontSize: 30,
			}}>
			{`Matches à venir`}
		</Text>

		<Text
			style={{
				paddingBottom: 50,
				textAlign: "center",
				color: 'grey'
			}}>
			{`Selectionnez un match et entrez votre propre pronostique`}
		</Text>
	</View>
);

const keyExtractor = pipe(nthArg(1), String);

const localStyles = {
	layout: {
		flex: 1,
	},

	content: {
		maxWidth: styles.maxAppWidth + 12,
		paddingBottom: 50,
		alignSelf: 'center',
		width: '100%',
	},

	item: {
		marginBottom: 50,
		marginHorizontal: 20,
		width: '90%',
		maxWidth: styles.maxAppWidth * .75,
		alignSelf: 'center',
	},
};
