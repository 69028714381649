export default function setupCSS(name, font){
const iconFontStyles = `@font-face {
	src: url('${font}') format('truetype');
	font-family: ${name};
  }`;
  
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(iconFontStyles));
  
  document.head.appendChild(style);
}