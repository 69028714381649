import { DocumentNode, gql, OperationVariables, useQuery } from '@apollo/client';
import { identity, or, pipe, prop, __ } from 'ramda';
import React, { useState } from 'react';
import { FlatList, ScrollView, TextInput, View, ViewStyle } from 'react-native';
import Portal from "rn-tools/component/Portal";
import use from "rn-tools/hook";
import { useDebouncedEffect } from 'rn-tools/hook/useEffect';
import useQueryIterator from "rn-tools/hook/useQueryIterator";
import useQueryLoader from "rn-tools/hook/useQueryLoader";
import useRouteParamState from "rn-tools/hook/useRouteParamState";
import { falsy } from '../../@types/utils';
import MaterialIcon from '../../component/icon/Material';
import MatchView from '../../component/MatchView';
import Text from '../../component/Text';
import UserView from '../../component/UserView';
import { Match, Query_Root, User } from '../../generated/graphql';
import { colors, styles } from '../../res';
import screens from "../../screens";

function SearchUI({ route, navigation }) {
	// TODO FIX no sync with url when page loaded with query already set
	const [typedQuery, setQuery] = useRouteParamState('query', {
		read: or(__, ""),
		write: identity,
	});

	const [searchTxt, setSearchTxt] = useState(typedQuery);
	useDebouncedEffect(800, () => setQuery(searchTxt), [searchTxt]);


	const kind: SearchKind | undefined = route.params?.kind as SearchKind;
	const setKind = (kind: SearchKind) => navigation.push(screens.search, { kind, query: searchTxt });

	const [graphqlDocument, variables] = buildGraphqlQuery(typedQuery, kind);

	const loader = useQuery(graphqlDocument, { variables, fetchPolicy: 'no-cache' });

	//@ts-ignore
	const iterator = useQueryIterator(loader, {
		retrieveItems: prop(kind),
		range: NUMBER_PER_PAGE,
	});

	// https://github.com/react-navigation/react-navigation/issues/9875
	const autoFocusWorkaround = use.callback(input => setTimeout(() => input?.focus?.(), 1000));

	return (
		<View style={{ flex: 1 }}>
			{
				!kind ?
					// suggestions
					<ScrollView {...localStyles.scroll}>
						{
							[SearchKind.matches, SearchKind.users]
								.map(kind => {
									const { title, select, renderItem } = RES[kind];

									return (
										<View
											key={kind}
											style={{ maxWidth: 450, width: '90%', marginHorizontal: 5, }}>
											<Text style={{ fontSize: 20, fontWeight: 'bold', paddingVertical: 10, marginVertical: 10, borderBottomWidth: .5, borderColor: 'white' }}>
												{title}
											</Text>

											{
												(loader.data?.[kind] || [null, null])
													.slice(0, 3)
													.map(renderItem)
											}

											{
												loader.data?.[String(kind)]?.length >= 3 ?
													<Text
														onPress={() => setKind(kind)}
														style={{ paddingVertical: 10, marginLeft: 9 }}>
														{select} ›
													</Text> :

													loader.data?.[kind]?.length === 0 &&
													emptyTxt(kind)
											}
										</View>
									)
								})
						}
					</ScrollView> :

					// infinite list of one type
					<FlatList
						data={iterator.items}
						keyExtractor={(item, index) => item?.id || index}
						renderItem={pipe(prop('item'), RES[kind].renderItem)}

						ListFooterComponent={
							!iterator.end ? (
								<>
									{RES[kind].renderItem(undefined)}
									{
										!iterator.items?.length &&
										RES[kind].renderItem(undefined, -1)
									}
								</>
							) :
								// empty
								!iterator.items?.length ? emptyTxt(kind) : undefined
						}

						onEndReached={iterator.willLoadNext({ [RES[kind].cursor]: iterator.items?.length })}
						onEndReachedThreshold={.01}
						{...localStyles.list}
					/>
			}

			<View style={localStyles.searchBarWrapper}>
				<View style={localStyles.searchBar}>
					<MaterialIcon
						name='search'
						color='white'
						size={25}
						style={localStyles.searchIcon}
					/>

					<TextInput
						ref={autoFocusWorkaround}
						value={searchTxt}
						onChangeText={setSearchTxt}
						placeholder={`Match, pronostiqueur...`}
						autoFocus
						style={localStyles.input}
					/>

				</View>

			</View>
		</View>
	);
}

export default React.memo(SearchUI);

const NUMBER_PER_PAGE = 20;

const emptyTxt = (kind: SearchKind) => (
	<Text style={{ textAlign: 'center', maxWidth: 350, alignSelf: 'center', fontSize: 16 }}>
		{`Aucun ${kind === SearchKind.users ? `de nos pronostiqueurs` : `match`} ne correspond à votre recherche.`}
	</Text>
);

enum SearchKind {
	users = 'users',
	matches = 'matches',
}

const RES = {
	matches: {
		cursor: 'matchesOffset',
		title: `Matches`,
		select: `Voir plus de matches`,
		renderItem(item: any, index?: number) {
			return (
				<Portal
					key={item?.id || index}
					to={screens.match}
					with={{ id: item?.id }}
					disabled={!item}>
					<MatchView
						match={item}
						style={localStyles.item} />
				</Portal>
			);
		}
	},

	users: {
		cursor: 'usersOffset',
		title: `Pronostiqueurs`,
		select: `Voir plus de pronostiqueurs`,
		renderItem(item: any, index?: number) {
			return (
				<Portal
					key={item?.id || index}
					to={screens.user}
					with={{ id: item?.id }}
					disabled={!item}>
					<UserView
						user={item}
						style={localStyles.item} />
				</Portal>
			);
		},
	},
};


function buildGraphqlQuery(typedQuery: string, kind: SearchKind | undefined): [DocumentNode, OperationVariables] {
	const matchesListFragment = (() => {
		const teamFragment = gql`fragment teamFragment on team {
			id
			logo
			name
		}`;

		const matchFragment = gql`fragment matchFragment on match {
			id
			league
			score1
			score2
			team1 { ...teamFragment }
			team2 { ...teamFragment }
			time
		} ${teamFragment}`;

		return gql`fragment matchesListFragment on query_root {
			matches: match(
				where: $matchFilter, 
				order_by: $matchSort, 
				limit: 20, 
				offset: $matchesOffset
			) {
				...matchFragment
			}
		} ${matchFragment}`;
	})();


	const usersListFragment = (() => {
		const userFragment = gql`fragment userFragment on user {
			id
			name
			picture
			pseudo
			score
		}`;

		return gql`fragment usersListFragment on query_root {
			users: user(
				where: {_or: [
					{name: {_ilike: $query}}, 
					{pseudo: {_ilike: $query}}
				]},
				order_by: {score: desc}, 
				limit: 20, 
				offset: $usersOffset
			) {
				...userFragment
			}
		} ${userFragment}`
	})();


	function buildDocumentFromFragments(fragments: (DocumentNode | falsy)[]) {
		const definitions = fragments.filter(Boolean) as DocumentNode[];

		const names = definitions.map(definition => definition.definitions[0]["name"].value) as string[];

		return gql`query SearchUI(
				$query: String = "%%", 
				$matchFilter: match_bool_exp, 
				$matchSort: [match_order_by!],
				$matchesOffset: Int = 0,
				$usersOffset: Int = 0,
			) {
			${names.map((name) => `...${name}`)
				.join('\n')
			}
		} ${definitions.map(doc => doc.loc?.source.body).join('\n')}`;
	};


	const variables = (() => {
		const _ilike = `%${typedQuery}%`;
		const matchFilter = typedQuery ? {
			_or: [
				{ team1: { name: { _ilike } } },
				{ team2: { name: { _ilike } } },
				{ league: { _ilike } },
			]
		} : { // no query, show coming matches
			time: { _gte: "now()" }
		};

		const matchSort = typedQuery ? null : { time: "asc" };

		return {
			query: _ilike,
			matchFilter,
			matchSort,
			matchesOffset: null,
			usersOffset: null,
		};
	})();


	return [
		buildDocumentFromFragments([
			(!kind || kind === SearchKind.users) &&
			usersListFragment,
			(!kind || kind === SearchKind.matches) &&
			matchesListFragment,
		]),
		variables,
	];
}

const localStyles = {
	scroll: {
		style: {
			flex: 1
		} as ViewStyle,

		contentContainerStyle: {
			maxWidth: styles.maxAppWidth,
			width: '100%',
			alignSelf: 'center',
			marginTop: 130,
			marginBottom: 50,

			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',
		} as ViewStyle
	},

	list: {
		style: {
			flex: 1
		} as ViewStyle,

		contentContainerStyle: {
			maxWidth: styles.maxAppWidth,
			width: '90%',
			alignSelf: 'center',
			marginTop: 130,
			marginBottom: 50,
		} as ViewStyle
	},

	searchBarWrapper: {
		alignItems: 'center',
		...styles.absolute({
			top: 25,
			left: 0,
			right: 0,
		})
	},

	searchBar: {
		height: 50,
		borderRadius: 25,
		width: '90%',
		maxWidth: 500,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.25,
		shadowRadius: 5,
		borderColor: 'grey',
		backgroundColor: colors.background(2),

		flexDirection: 'row',
	} as ViewStyle,

	searchIcon: {
		alignSelf: 'center',
		marginLeft: 15,
	},

	input: {
		flex: 1,
		fontSize: 20,
		color: 'white',
		paddingLeft: 15,
	},

	item: {
		padding: 15,
		marginVertical: 10,
		borderRadius: 9,
		borderWidth: 1,
		borderColor: 'grey',
		width: '100%',
		maxWidth: 450,
		alignSelf: 'center',
	}
};