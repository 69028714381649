import React from "react"
import View from "rn-tools/component/View";
import Portal from "rn-tools/component/Portal";
import PronosticRow from "./PronosticRow";
import screens from "../../screens"

/**
 * @param {*}
 */
export default function PronosticsList({ items, by, loadingRows, ...props }) {
	items = items || [];
	loadingRows = Number(loadingRows) || (loadingRows && 1);
	if (loadingRows > 0)
		items = items.concat(new Array(loadingRows).fill(null));

	return (
		<View {...props}>
			{
				items?.map((pronostic, index) =>
					<Portal key={index}
						to={screens.pronostic}
						with={{ id: pronostic?.id }}
						disabled={!pronostic}>
						<PronosticRow
							by={by}
							item={pronostic}
							style={localStyles.item}
						/>
					</Portal>
				)
			}
		</View>
	);
}

const localStyles = {
	item: {
		marginVertical: 15,
	}
};