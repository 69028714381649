import useUser from "rn-tools/hook/useUser";
import useQueryLoader from "rn-tools/hook/useQueryLoader";
import { gql } from "@apollo/client";
import { pickAll, pipe, defaultTo } from "ramda"

export default function useDBUser() {
	const user = useUser();
	const { data } = useQueryLoader(user && QUERY, buildParams(user));
	return user && data?.user_by_pk;
}


export const QUERY = gql`query useDBUser_hook($uid: String!) {
	user_by_pk(id: $uid) {
		id
		name
		pseudo
		score
	}
}`;

export const buildParams = pipe(defaultTo({}), pickAll(["uid"]));