import { gql } from "@apollo/client";
import MatchHeader from "@src/component/MatchHeader";
import React from 'react';
import { ActivityIndicator, FlatList, Pressable, View } from "react-native";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import Portal from "rn-tools/component/Portal";
import use from "rn-tools/hook";
import useQueryIterator from "rn-tools/hook/useQueryIterator";
import useQueryLoader from "rn-tools/hook/useQueryLoader";
import useUser from "rn-tools/hook/useUser";
import MaterialIcon from "../../component/icon/Material";
import ConfusedIcon from "../../component/icon/svg/Confused";
import SoccerFieldIcon from "../../component/icon/svg/SoccerField";
import PronosticRow from "../../component/PronosticsList/PronosticRow";
import Text from "../../component/Text";
import ErrorView from "../../component/ErrorView";
import { colors, styles } from '../../res';
import screens from "../../screens";
import createAppURL from "../../utils/createAppURL";
import isMatchYetToCome from "../../utils/isMatchYetToCome";
import UserPronosticPreview from "./UserPronosticPreview";

function MatchUI({ route }) {
	const id = route.params?.id;
	const user = useUser();
	const ready = (user !== undefined);

	const loader = useQueryLoader(ready && QUERY, {
		id,
		uid: user?.uid || "", // if undefined, all pronostics will be returned
	});
	const iterator = useQueryIterator(loader, {
		retrieveItems: data => data.match_by_pk?.pronostics || [],
		range: NUMBER_PER_PAGE,
	});
	const { data, loading = !ready } = loader;
	const match = data?.match_by_pk;
	const noMatch = data && !match; // TODO set status 404

	const [sharing, setSharing] = use.state(false);
	const share = () => setSharing(true);

	const userPronostic = match?.userPronostics?.[0];
	const userPronosticURL = createAppURL(screens.pronostic, { id: userPronostic?.id });
	const matchIsYetToCome = isMatchYetToCome(match);

	console.log("items:", iterator.items);

	return (
		<FlatList
			ListHeaderComponent={
				(match || loading) ?
					<>
						<MatchHeader
							match={match}
							loading={loading}
							style={localStyles.header}
						/>


						{
							UserPronosticPreview.willDisplay(match, userPronostic, loading) &&
							<View style={localStyles.userPronosticContainer}>
								<View style={[localStyles.userPronosticHeader, !data && { opacity: 0 }]}>
									<Text style={localStyles.userPronosticTitle}>
										{`Mon pronostique`}
									</Text>

									{
										userPronostic &&
										<>
											{
												sharing ?
													<>

														<View style={localStyles.userPronosticAction}>
															<TwitterShareButton
																url={userPronosticURL}
																style={localStyles.userPronosticAction}>
																<TwitterIcon size={32} round />
															</TwitterShareButton>
														</View>

														<View style={localStyles.userPronosticAction}>
															<FacebookShareButton
																url={userPronosticURL}
																style={localStyles.userPronosticAction}>
																<FacebookIcon size={32} round />
															</FacebookShareButton>
														</View>
													</> :
													<Pressable onPress={share} style={localStyles.userPronosticAction}>
														<MaterialIcon name="share" size={25} color="grey" />
													</Pressable>
											}

											{
												matchIsYetToCome &&
												<Portal to={screens.editPronostic} with={{ matchId: id }}>
													<View style={localStyles.userPronosticAction}>
														<MaterialIcon name="edit" size={25} color="grey" />
													</View>
												</Portal>
											}
										</>
									}

								</View>

								<Portal
									to={userPronostic ? screens.pronostic : screens.editPronostic}
									with={userPronostic ? { id: userPronostic?.id } : { matchId: id }}
									disabled={loading || !user || (!matchIsYetToCome && !userPronostic)}>
									<UserPronosticPreview
										pronostic={userPronostic}
										match={match}
										loading={loading}
										style={localStyles.userPronostic}
									/>
								</Portal>
							</View>
						}

						{
							(iterator?.items?.length > 0) &&
							<Text style={{ margin: 15, fontSize: 18, fontWeight: 'bold' }}>
								{`Les pronostiques de nos utilisateurs`}
							</Text>
						}
					</> :

					noMatch ?
						<View style={{ alignItems: 'center', alignSelf: 'center' }}>
							<SoccerFieldIcon size={250} />
							<ConfusedIcon
								size={100}
								style={{ position: 'absolute', top: 130, right: -20 }} />

							<Text style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>
								{`Ce match ne semble pas exister`}
							</Text>

							<Portal to={screens.matchList}>
								<Text style={{ textAlign: 'center', color: colors.accent(), paddingVertical: 20 }}>
									{`Afficher les prochains matches →`}
								</Text>
							</Portal>
						</View> :

						<ErrorView retry={loader.refetch} />
			}

			data={iterator.items}
			keyExtractor={pronostic => String(pronostic.id)}
			renderItem={({ item }) =>
				<Portal
					to={screens.pronostic}
					with={{ id: item.id }}>
					<PronosticRow
						by="user"
						item={item}
						style={localStyles.row}
					/>
				</Portal>
			}


			onEndReached={iterator.willLoadNext({ offset: iterator.items?.length })}
			onEndReachedThreshold={.2}

			ListFooterComponent={
				!iterator.end && loader.data &&
				<ActivityIndicator />
				// TODO placeholders
			}

			contentContainerStyle={localStyles.content}
			style={localStyles.layout} />
	);
}

export default React.memo(MatchUI);

const NUMBER_PER_PAGE = 20;

const QUERY = gql`query ($id: Int! = -1, $uid: String, $offset: Int) {
	match_by_pk(id: $id){
	  id
	  time
	  score1
	  score2
	  league

	  team1 {
		id
		logo
		name
	  }

	  team2 {
		id
		logo
		name
	  }

	  userPronostics: pronostics(where: {user: {firebaseId: {_eq: $uid}}}) {
		id
		score1
		score2
		comment
		score
	  }

		pronostics(
			where: {user: {firebaseId: {_neq: $uid}}}, 
			order_by: {user: {pronostics_aggregate: {count: desc}}}, 
			offset: $offset, 
			limit: ${NUMBER_PER_PAGE}
		)  {
		id
		score1
		score2

		match {
			id
			score1
			score2
		}

		user {
		  id
		  name
		  pseudo
		  picture
		  score
		  
		  pronostics_aggregate {
			aggregate {
			  count
			}
		  }
		}
	  }
	}
}`;


const localStyles = {
	layout: {
	},

	content: {
		alignSelf: styles.alignSelf.center,
		width: "100%",
		maxWidth: styles.maxAppWidth + 12,
		paddingVertical: 50,
		flexGrow: 1,
	},

	header: {
		marginBottom: 50,
	},

	userPronosticHeader: {
		flexDirection: 'row',
		alignSelf: 'center',
		maxWidth: 700,
		width: '100%',
		marginTop: 40,
		marginBottom: 20,
	},

	userPronosticTitle: {
		fontWeight: 'bold',
		fontSize: 25,
		flex: 1,
		alignSelf: 'center',
	},

	userPronosticAction: {
		...styles.center,
		paddingHorizontal: 10,
	},

	userPronosticContainer: {
		marginBottom: 40,
		paddingHorizontal: 20,
		maxWidth: 700,
		alignSelf: 'center',
		width: '100%',
	},

	userPronostic: {
		padding: 40,
		paddingTop: 20,
		borderRadius: 10,
		borderWidth: 1,
		borderColor: 'lightgrey',
	},

	row: {
		marginVertical: 20,
		marginHorizontal: 12,
	}
};
