import { TouchableOpacity, Image } from "react-native";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth"
import React from "react"
import use from "rn-tools/hook";
import { images } from "../../res";
import Text from "@src/component/Text"

export default function AuthButton({ provider: providerName, imageStyle, label, then, ...props }) {
	props.onPress = () => {
		const provider = PROVIDERS[providerName]();


		const promise = signInWithPopup(getAuth(), provider);
		if (then)
			promise.then(then);

		promise.catch(error => {
			console.error(error.code, error.message, error);

			// The email of the user's account used.
			// var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			// var credential = error.credential;
		});
	};

	const isGoogle = providerName === "google";
	props.style = use.style(props.style, [
		styles.layout,
		isGoogle && {
			borderLeftColor: '#F4B400',
			borderTopColor: '#DB4437',
			borderRightColor: '#4285F4',
			borderBottomColor: '#0F9D58',
		}
	]);
	return (
		<TouchableOpacity {...props}>
			<Image
				source={images[providerName + 'Icon']}
				resizeMode="contain"
				style={[styles.logo, imageStyle].flat()} />

			<Text style={styles.text}>
				{label || `Se connecter`}
			</Text>
		</TouchableOpacity>
	);
}

const PROVIDERS = {
	google() {
		const provider = new GoogleAuthProvider();
		provider.addScope('https://www.googleapis.com/auth/userinfo.email');
		return provider;
	},
	facebook() {
		const provider = new FacebookAuthProvider();
		provider.addScope('email');
		return provider;
	},
};

const styles = {
	layout: {
		flexDirection: 'row',
		borderWidth: 1,
		borderColor: 'black',
		borderRadius: 36,
		height: 50,
		width: 180,
		alignItems: 'center',
		justifyContent: 'center',
	},

	logo: {
		height: 30,
		width: 30,
		marginRight: 20,
	},

	text: {
		fontWeight: 'bold',
	}
};