import { gql } from "@apollo/client";
import { useRoute } from "@react-navigation/native";
import React from "react";
import { ScrollView, View, ViewStyle } from "react-native";
import Portal from "rn-tools/component/Portal";
import useQueryIterator from 'rn-tools/hook/useQueryIterator';
import useQueryLoader from 'rn-tools/hook/useQueryLoader';
import onEndReached from "rn-tools/utils/onEndReached";
import ErrorView from "../../component/ErrorView";
import ConfusedIcon from "../../component/icon/svg/Confused";
import PronosticsList from "../../component/PronosticsList";
import ShortMatchList from "../../component/ShortMatchesList";
import Text from "../../component/Text";
import UserHeader from "../../component/UserHeader";
import { Query_Root } from "../../generated/graphql";
import useDBUser from "../../hook/useDBUser";
import { colors, styles } from "../../res";
import screens from "../../screens";
import { parseUserUIParams } from "./params";

function UserUI() {
	const route = useRoute();
	const { id, ...params } = parseUserUIParams(route.params);

	const loader = useQueryLoader<Query_Root>(QUERY, { id });
	const iterator = useQueryIterator(loader, {
		//@ts-ignore
		retrieveItems: data => data.user_by_pk?.pronostics || [],
		range: NUMBER_PER_PAGE,
	});

	const { data, refetch, loading } = loader;
	//@ts-ignore
	const profile = data?.user_by_pk;

	const dbUser = useDBUser();
	const isCurrentUser = Boolean(dbUser && profile?.id === dbUser?.id);

	// infinite load of pronostics
	const loadNextPageAtBottom = onEndReached(iterator.willLoadNext({ offset: profile?.pronostics?.length }));

	let pronostics = iterator.items?.slice() || [];
	if (!iterator.end && !loader.error) {
		pronostics.push(null);
		if (loader.loading)
			pronostics.push(null);
	}

	return (
		<ScrollView
			style={localStyles.layout}
			contentContainerStyle={localStyles.content}
			onScroll={loadNextPageAtBottom}>
			{
				(profile || loading) ?
					<>
						<UserHeader
							user={profile}
							editable
							startEdit={params.edit}
							style={localStyles.header} />

						<PronosticsList
							items={pronostics}
						/>

						{
							isCurrentUser &&
							iterator.end &&
							<>
								<Text style={localStyles.nextMatchesTitle}>
									{
										iterator.items?.length === 0 ?
											`Ici s'afficheront vos pronostiques.\nSelectionnez un match ci dessous, entrez un pronostique et faites grimper votre score !`
											: `Ne vous arretez pas maintenant.\nAjoutez de nouveaux pronostiques à votre profile.`
									}
								</Text>

								<Text style={localStyles.nextMatchesTitle}>
									{`Les prochains matches`}
								</Text>

								<ShortMatchList />
							</>
						}
					</> :

					data ?
						<View style={{ alignItems: 'center', alignSelf: 'center' }}>
							<ConfusedIcon size={140} style={{ paddingLeft: 30, marginTop: 40 }} />

							<Text style={{ textAlign: 'center', marginTop: 60, fontWeight: 'bold', fontSize: 20 }}>
								{`Cet utilisateur ne semble pas exister`}
							</Text>

							<Portal to={screens.matchList}>
								<Text style={{ textAlign: 'center', color: colors.accent(), paddingVertical: 20 }}>
									{`Retour à l'accueil →`}
								</Text>
							</Portal>
						</View> :

						<ErrorView retry={refetch} />
			}
		</ScrollView>
	);
}

export default React.memo(UserUI);

const NUMBER_PER_PAGE = 20;

const QUERY = gql`query ($id: String! = "", $offset: Int = 0) {
	user_by_pk(id: $id) {
		id
		firebaseId
		name
		picture
		pseudo
		score
		numberOfPronostics

		pronostics(order_by: {match: {time: desc}}, limit: ${NUMBER_PER_PAGE}, offset: $offset) {
			id
			score
			score1
			score2
			comment
			match {
				id
				score1
				score2
				time
				league
				team1 {
					name
				  	logo
				}
				team2 {
				  	name
				  	logo
				}
		  }
		}
	}
}`;


const localStyles = {
	layout: {},

	content: {
		alignSelf: styles.alignSelf.center,
		width: "100%",
		maxWidth: styles.maxAppWidth + 12,
		paddingHorizontal: 12,
		paddingVertical: 50,
	} as ViewStyle,

	header: {
		marginBottom: 80,
	},

	nextMatchesTitle: {
		textAlign: 'center',
		fontSize: 20,
		marginVertical: 20,
		lineHeight: '180%',
	},
}