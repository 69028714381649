import MatchView from "@src/component/MatchView";
import React from "react";
import { View } from "react-native";
import use from "rn-tools/hook";
import memoize from "../../../hoc/memoize";
import { styles } from "../../../res";
import ScoresView from "./ScoresView";
import UserView from "../../UserView";

function PronosticRow({ item: pronostic, by, ...props }) {
	const byUser = by === "user";
	const [level, onLayout, { select }] = use.responsive(levels);
	props.style = use.style(props.style, localStyles.layout, [level]);

	return (
		<View onLayout={onLayout} {...props}>
			{
				level &&
				<>
					<View style={
						select({
							s: {
								flexDirection: 'row',
								...styles.center,
								maxWidth: '100%',
							},
							default: { flex: 1 },
						})
					}>
						{
							byUser ?
								<UserView
									user={pronostic?.user}
									style={localStyles.mainSide}
								/> :
								<MatchView
									match={pronostic?.match}
									style={localStyles.mainSide}
								/>
						}

					</View>

					<View style={localStyles.separator(level)} />

					<ScoresView
						pronostic={pronostic}
						style={select({
							s: {
								marginVertical: 20,
							},
							default: { flex: 1 },
						})}
					/>
				</>
			}
		</View>
	);
}

export default memoize(PronosticRow);

const levels = { s: 585 };

const localStyles = {
	layout: level => ({
		borderWidth: level && 1,
		borderColor: 'grey',
		flexDirection: level > levels.s ? 'row' : undefined,
		borderRadius: 8,
	}),

	mainSide: {
		paddingVertical: 30,
		alignSelf: 'center',
		width: '90%',
		maxWidth: 400,
	},

	scoreSide: {
		// flex: 1,
	},

	separator: level => ({
		height: level > levels.s ? "80%" : 1,
		width: level > levels.s ? 1 : '10%',
		backgroundColor: 'grey',
		alignSelf: 'center',
	}),
};