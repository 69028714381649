import React from 'react';

export default function BlankPageIcon({size, ...props}) {
	return (
		<svg height={size} viewBox="-48 0 512 512" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="m32 16h352c17.671875 0 32 14.328125 32 32v432c0 17.671875-14.328125 32-32 32h-352c-17.671875 0-32-14.328125-32-32v-432c0-17.671875 14.328125-32 32-32zm0 0" fill="#ecce93" />
			<path d="m32 374.625v-326.625h352v432h-246.625zm0 0" fill="#e4edfb" />
			<g fill="#00b55b">
				<path d="m137.375 374.625v105.375l-105.375-105.375zm0 0" />
				<path d="m128 64v-64h160v64c0 17.671875-14.328125 32-32 32h-96c-17.671875 0-32-14.328125-32-32zm0 0" />
			</g>
		</svg>
	);
}