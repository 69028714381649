import { gql } from "@apollo/client"
import React from 'react'
import { View } from "react-native"
import Portal from "rn-tools/component/Portal"
import useStyle from "rn-tools/hook/useStyle"
import useMemory from "rn-tools/hook/useMemory"
import useQueryLoader from "rn-tools/hook/useQueryLoader"
import { colors } from "../res"
import screens from "../screens"
import MatchView from "./MatchView"
import Text from "./Text"


export default function ShortMatchList({ limit = 12, ...props }) {
	const variables = useMemory((_, variables, [oldLimit] = []) => {
		if (!variables || limit > oldLimit)
			variables = { limit };
		return variables;
	}, [limit]);
	const { data, loading } = useQueryLoader(QUERY, variables);
	props.style = useStyle(props.style, commonStyles.layout);

	return (
		<View {...props}>
			{
				(data?.match || new Array(loading ? limit : 0).fill())
					.slice(0, limit)
					.map((match, index) => {
						const isLast = limit - 1 === index;

						return (
							<Portal
								key={index}
								to={isLast ? screens.matchList : screens.match}
								with={{ id: match?.id }}
								disabled={!match}>
								<MatchView
									match={match}
									style={commonStyles.match}>
									{
										isLast && match &&
										<Text style={{ textAlign: 'center', fontSize: 16 }}>
											{`Voir plus ›`}
										</Text>
									}
								</MatchView>
							</Portal>
						);
					})
			}
		</View>
	);
}



const QUERY = gql`query ShortMatchesList_component($limit: Int = 6) {
	match(
		limit: $limit,
		order_by: { time: asc },
		where: { time: { _gt: "now()" } }
	) {
		id
		time
		league

		team1 {
			name
			logo
		}

		team2 {
			name
			logo
		}
	}
}`;

const commonStyles = {
	layout: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
	},

	match: {
		marginVertical: 15,
		marginHorizontal: 5,

		backgroundColor: colors.background(2),
		paddingVertical: 10,
		paddingHorizontal: 15,

		borderWidth: 1,
		borderColor: 'grey',
		borderRadius: 8,

		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.25,
		shadowRadius: 5,

		width: 300,
	}
};