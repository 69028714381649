import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import { is, last, test } from "ramda";
import React, { useEffect, useState } from "react";
import {
	Image, Pressable, TextInput, View
} from 'react-native';
import { toast } from "react-toastify";
import Portal from "rn-tools/component/Portal";
import useUser from "rn-tools/hook/useUser";
import AuthButton from "../component/button/AuthButton";
import MaterialIcon from "../component/icon/Material";
import ResponsiveModal from "../component/ResponsiveModal";
import Text from "../component/Text";
import useDBUser from "../hook/useDBUser";
import { colors, images, styles } from "../res";
import screens from "../screens";

function AuthModal({ navigation, route }) {
	const onceAuthenticatedParam = route.params?.onceAuthenticated;

	const [hasJustAuthenticated, setHasJustAuthenticated] = useState(false);
	async function onceAuthenticated(user) {
		setHasJustAuthenticated(true);
		if (is(Function, onceAuthenticatedParam))
			onceAuthenticatedParam(user);
	}

	const user = useUser();
	const dbUser = useDBUser();

	useEffect(() => { // redirect user to his profile when he connects
		if (user && dbUser && hasJustAuthenticated)
			navigation.replace(screens.user, { id: dbUser.id });
	}, [Boolean(user), Boolean(dbUser)]);

	const [emailFocus, setEmailFocus] = useState(false);
	const [email, setEmail] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const [sendingEmail, setSendingEmail] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const emailValid = isEmailValid(email);
	function submitEmail() {
		setSubmitted(true);

		if (emailValid) {
			setSendingEmail(true);

			sendEmailLink(email)
				.then(() => {
					setEmailSent(true);
					window.localStorage.setItem(LOCAL_EMAIL_KEY, email);
				})
				.catch(error => {
					console.error(error);
					toast.dark(`❗️  ${error.message}`);
				})
				.finally(() => setSendingEmail(false));
		}
		else
			toast.dark(`❌  Email invalide.`);
	}



	return (
		<ResponsiveModal style={localStyles.layout}>

			<UserHeader />

			{SEPARATOR}


			<View style={{ alignItems: 'center' }}>
				{
					user ?
						<Text>
							{
								dbUser ? `Connecté !`
									: `Connexion...`
							}
						</Text> :

						emailSent ?
							<>
								<Text>
									{`Un email avec un lien de connexion a été envoyé à l'adresse ${email}. Ouvrez votre boite mail et cliquez sur le lien afin de finaliser votre connexion. Pensez à vérifier les spams.`}
								</Text>

								<Pressable
									href={`https://${last(email.split('@'))}`}
									hrefAttrs={{ target: "blank" }}
									style={localStyles.openMailBox}>
									<Text
										style={localStyles.openMailBoxText}>
										{`Ouvrir ${last(email.split('@'))}`}
									</Text>
								</Pressable>
							</> :

							<>
								<View style={localStyles.email}>
									<MaterialIcon
										name="email"
										color={colors.accent(0)}
										size={24}
										style={localStyles.emailIcon} />

									<TextInput
										value={email}
										onChangeText={setEmail}
										onFocus={() => setEmailFocus(true)}
										onBlur={() => setEmailFocus(false)}
										placeholder={`Se connecter par email`}
										placeholderTextColor={emailFocus ? 'grey' : 'white'}
										onSubmitEditing={submitEmail}
										autoComplete="email"
										keyboardType="email-address"
										textContentType="emailAddress"
										style={localStyles.emailInput(!submitted || emailValid)}
									/>
								</View>

								{
									Boolean(email || emailFocus) ?
										<Pressable
											onPress={submitEmail}
											disabled={sendingEmail}
											style={localStyles.emailSubmit}>
											<Text style={localStyles.emailSubmitTxt}>
												{
													sendingEmail ? `Envoi en cours...`
														: `Entrer`
												}
											</Text>
										</Pressable> :

										<AuthButton
											provider="google"
											then={onceAuthenticated}
											style={localStyles.google} />
								}

							</>
				}
			</View>

		</ResponsiveModal>
	);
}

export default React.memo(AuthModal);

function UserHeader() {
	const dbUser = useDBUser();
	const user = useUser();
	return (
		<Portal to={screens.user} with={{ id: dbUser?.id }} disabled={!dbUser}>
			<View>
				<View style={localStyles.userHeader}>
					<Image
						source={user && { uri: user.photoURL }}
						defaultSource={images.user}
						resizeMode="cover"
						style={localStyles.userPicture}
					/>

					<Text style={localStyles.userName}>
						{user?.displayName || `Profile`}
					</Text>
				</View>

				<Text style={{ color: 'grey', fontStyle: 'italic', marginTop: 10, textAlign: 'center' }}>
					{user ? '' : `Connectez vous via\nun des moyens ci-dessous`}
				</Text>
			</View>
		</Portal>
	);
}

const SEPARATOR = (
	<View style={{
		height: .5,
		backgroundColor: "white",
		alignSelf: 'center',
		marginVertical: 30,
		width: '50%',
	}} />
);

const isEmailValid = test(/^[0-9a-zA-Z-_$.]+\@([0-9a-zA-Z-]+\.)+[a-z]+$/);
const sendEmailLink = (email, url = window.origin) => sendSignInLinkToEmail(getAuth(), email, {
	url,
	handleCodeInApp: true,
});

export const LOCAL_EMAIL_KEY = "email_to_signin";

export function checkAuthURL() {
	const email = localStorage.getItem(LOCAL_EMAIL_KEY);
	if (email) {
		const auth = getAuth();
		const currentURL = window.location.href;
		if (isSignInWithEmailLink(auth, currentURL)) {
			signInWithEmailLink(auth, email, currentURL)
				.catch(console.error);
		}

		localStorage.removeItem(LOCAL_EMAIL_KEY); // clean
	}
}

const localStyles = {
	layout: {
		padding: 40,
		maxWidth: 600,
	},

	userHeader: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},

	userPicture: {
		...styles.circle(40),
		marginRight: 20,
		borderWidth: 1,
		borderColor: 'white',
	},

	userName: {
		fontWeight: 'bold',
		fontSize: 20,
	},


	email: {
		height: 50,
		borderRadius: 25,
		borderColor: colors.accent(0),
		borderWidth: 1,
		flexDirection: 'row',
		marginBottom: 20,
		width: '100%',
		maxWidth: 300,
		justifyContent: 'center'
	},

	emailIcon: {
		alignSelf: 'center',
		marginLeft: 15,
	},

	emailInput: ok => ({
		flex: 1,
		paddingLeft: 10,
		paddingRight: 5,
		fontWeight: 'bold',
		color: ok ? 'white' : 'red',
		cursor: 'pointer',
	}),

	emailSubmit: {
		...styles.center,
		backgroundColor: colors.accent(1),
		height: 50,
		width: '100%',
		maxWidth: 300,
		borderRadius: 10,
	},

	emailSubmitTxt: {
		fontWeight: 'bold',
		fontSize: 18,
	},

	google: {
		width: '100%',
		maxWidth: 300,
	},

	openMailBox: {
		...styles.center,
		borderColor: colors.accent(1),
		height: 50,
		width: '100%',
		maxWidth: 300,
		borderRadius: 10,
		marginTop: 20,
	},

	openMailBoxText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: colors.accent(1),
	}
};