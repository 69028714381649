import React from 'react';

export default function SoccerFieldIcon({size, ...props}) {
	return (
		<svg id="color_line" height={size} viewBox="0 0 74 74" width={size} xmlns="http://www.w3.org/2000/svg" data-name="color line" {...props}>
			<rect fill="#9297ab" height="47.887" rx="3.347" width="68" x="3" y="13.056" />
			<path d="m71 16.4v41.2a3.327 3.327 0 0 1 -2.81 3.29 2.95 2.95 0 0 0 .1-.79v-41.2a3.342 3.342 0 0 0 -3.35-3.34h-61.31a2.327 2.327 0 0 0 -.53.05 3.328 3.328 0 0 1 3.25-2.55h61.3a3.342 3.342 0 0 1 3.35 3.34z" fill="#84879c" />
			<path d="m17.845 7.789h38.31v58.423h-38.31z" fill="#6fc666" transform="matrix(0 1 -1 0 74 0)" />
			<path d="m66.21 17.85v38.31h-2.92v-35.4h-55.5v-2.91z" fill="#65b54e" />
			<g fill="#323c6b">
				<path d="m37 56.655a.5.5 0 0 1 -.5-.5v-38.31a.5.5 0 1 1 1 0v38.31a.5.5 0 0 1 -.5.5z" />
				<path d="m37 46.443a9.443 9.443 0 1 1 9.443-9.443 9.453 9.453 0 0 1 -9.443 9.443zm0-17.886a8.443 8.443 0 1 0 8.443 8.443 8.453 8.453 0 0 0 -8.443-8.443z" />
				<path d="m17.605 47.04h-9.816a.5.5 0 0 1 -.5-.5v-19.079a.5.5 0 0 1 .5-.5h9.817a.5.5 0 0 1 .5.5v19.079a.5.5 0 0 1 -.501.5zm-9.317-1h8.817v-18.079h-8.816z" />
				<path d="m17.605 42.851a.5.5 0 0 1 0-1 4.849 4.849 0 0 0 0-9.7.5.5 0 0 1 0-1 5.849 5.849 0 0 1 0 11.7z" />
				<path d="m66.211 47.04h-9.816a.5.5 0 0 1 -.5-.5v-19.079a.5.5 0 0 1 .5-.5h9.817a.5.5 0 0 1 .5.5v19.079a.5.5 0 0 1 -.501.5zm-9.317-1h8.817v-18.079h-8.816z" />
				<path d="m56.395 42.851a5.849 5.849 0 0 1 0-11.7.5.5 0 0 1 0 1 4.849 4.849 0 0 0 0 9.7.5.5 0 0 1 0 1z" />
				<path d="m59.875 64.444h-.792a.5.5 0 0 1 0-1h.792a.5.5 0 0 1 0 1z" />
				<path d="m56.583 64.444h-9.25a.5.5 0 0 1 0-1h9.25a.5.5 0 0 1 0 1z" />
				<path d="m.286 15.018a.51.51 0 0 1 -.149-.018.5.5 0 0 1 -.329-.626 6.82 6.82 0 0 1 6.539-4.818h1.32a.5.5 0 1 1 0 1h-1.32a5.824 5.824 0 0 0 -5.584 4.111.5.5 0 0 1 -.477.351z" />
				<path d="m10.833 10.556h-.583a.5.5 0 1 1 0-1h.583a.5.5 0 1 1 0 1z" />
				<path d="m66.211 56.655h-58.422a.5.5 0 0 1 -.5-.5v-38.31a.5.5 0 0 1 .5-.5h58.422a.5.5 0 0 1 .5.5v38.31a.5.5 0 0 1 -.5.5zm-57.923-1h57.423v-37.31h-57.422z" />
				<path d="m67.653 61.444h-61.306a3.851 3.851 0 0 1 -3.847-3.844v-41.2a3.851 3.851 0 0 1 3.847-3.847h61.306a3.851 3.851 0 0 1 3.847 3.847v41.2a3.851 3.851 0 0 1 -3.847 3.844zm-61.306-47.888a2.85 2.85 0 0 0 -2.847 2.844v41.2a2.85 2.85 0 0 0 2.847 2.847h61.306a2.85 2.85 0 0 0 2.847-2.847v-41.2a2.85 2.85 0 0 0 -2.847-2.847z" />
			</g>
			<path d="m26.563 53.525h-.792a.65.65 0 1 1 0-1.3h.792a.65.65 0 1 1 0 1.3z" fill="#fff" />
			<path d="m22.958 53.525h-8.258a.65.65 0 1 1 0-1.3h8.261a.65.65 0 1 1 0 1.3z" fill="#fff" />
		</svg>
	);
}