import { UIParameterKey } from "../../utils/UIParametersStore";

export enum ProdileUiEditParam {
	name = "name",
	pseudo = "pseudo",
	picture = "picture",
}

export type UserUIParams = { id: number } & (
	{ edit: ProdileUiEditParam.picture, pictureKey: UIParameterKey }
	| { edit?: ProdileUiEditParam.name }
)


export function parseUserUIParams(routeParams: any): Partial<UserUIParams> {
	return routeParams || {};
}