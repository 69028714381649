const screens = {
	home: 'home',
	matchList: 'matchList',
	user: 'user',
	match: 'match',
	pronostic: 'pronostic',
	editPronostic: 'editPronostic',
	search: 'search',
	authModal: 'authModal',
	profileModal: 'profileModal',
};

export default screens;

export const linking = {
	config: {
		screens: {
			home: '',
			matchList: 'match',
			match: 'match/:id',
			user: 'user',
			pronostic: 'pronostic',
			editPronostic: 'pronostic/edit',
			search: 'search/:kind?',
		}
	},
};