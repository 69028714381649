import { useNavigation } from "@react-navigation/native"
import MatchScores from "@src/component/MatchScores"
import Text from "@src/component/Text"
import TextHolder from '@src/component/TextHolder'
import React from 'react'
import { Image, TouchableOpacity, View } from "react-native"
import LoadingPlaceholder from 'rn-tools/component/LoadingPlaceholder'
import use from "rn-tools/hook"
import useUser from 'rn-tools/hook/useUser'
import { images, styles } from '../../res'
import screens from '../../screens'
import isMatchYetToCome from '../../utils/isMatchYetToCome.ts'

function UserPronosticPreview({ pronostic, match, loading, ...props }) {
	const user = useUser();
	const authReady = user !== undefined;
	loading = Boolean(loading || !authReady);

	const navigation = useNavigation();

	const matchIsToCome = isMatchYetToCome(match);
	if (!loading && matchIsToCome && !user)
		props.onPress = () => navigation.navigate(screens.authModal, {
			onceAuthenticated() {
				navigation.replace(screens.editPronostic, { matchId: match?.id })
			}
		});

	props.style = use.memory(() =>
		[localStyles.layout, props.style, { cursor: 'pointer' }],
		[props.style],
	);

	return willDisplay(match, pronostic, loading) && (
		<TouchableOpacity
			{...props}
			disabled={!props.onPress}>

			{/* user row */}
			<View style={localStyles.userRow}>
				{/* user picture */}
				<LoadingPlaceholder
					Component={Image}
					source={!loading && (user ? { uri: user.photoURL } : images.user)}
					animated={loading}
					style={localStyles.userPicture(loading)} />

				{/* user name */}
				<LoadingPlaceholder Component={Text} style={localStyles.userName(loading)} animated={loading}>
					{
						loading ? <TextHolder for="firstname lastname" /> :
							user ? user.displayName :
								`Je pronostique sur le match`
					}
				</LoadingPlaceholder>
			</View>


			{
				(loading || pronostic) ?
					<MatchScores
						scores={!loading && pronostic}
						style={localStyles.scores} /> :

					<Text style={[localStyles.scores, { fontSize: 16, fontWeight: 'bold' }]}>
						{`Entrez votre pronostique ›`}
					</Text>
			}


			{
				Boolean(pronostic?.comment?.trim()) &&
				<>
					{COMMENT_SEPARATOR}

					<Text style={localStyles.comment}>
						{pronostic.comment}
					</Text>
				</>
			}


		</TouchableOpacity>
	);
}

function willDisplay(match, pronostic, loading) {
	return loading || pronostic || isMatchYetToCome(match);
}

export default Object.assign(React.memo(UserPronosticPreview), { willDisplay });


const COMMENT_SEPARATOR = <View style={{ alignSelf: 'center', height: 1, width: 50, backgroundColor: 'grey', marginBottom: 30 }} />

const localStyles = {
	layout: {
	},

	userRow: {
		flexDirection: 'row',
		alignItems: 'center',
	},

	userPicture: loading => ({
		...styles.circle(35),
		marginRight: 20,
		borderWidth: loading ? 0 : 1,
		borderColor: 'white',

	}),

	userName: loading => ({
		fontWeight: 'bold',
		borderRadius: 4,
		paddingHorizontal: 2,
		fontSize: 20,
		flex: loading ? 0 : 1,
	}),

	scores: {
		textAlign: 'center',
		alignSelf: 'center',
		marginVertical: 30,
	},

	comment: {
		fontSize: 16,
	},

	authLayout: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		paddingTop: 40,
		flexWrap: 'wrap',
	},

	authButton: {
		marginBottom: 10,
		marginHorizontal: 2,
	}
};