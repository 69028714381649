import React from "react";
import { Image, View } from "react-native";
import use from "rn-tools/hook";
import styles from "../../res/styles";
import Text from "../../component/Text"
import IPronostic from "../../component/IPronostic";

export default function Section({ background, foreground, children, innerStyle, ...props }) {
	props.style = use.style(props.style, { minHeight: 150 });

	return (
		<View {...props}>
			<View style={{ flexDirection: 'row', justifyContent: "space-evenly", alignItems: 'center', flexWrap: 'wrap', marginHorizontal: 5, marginVertical: 20 }}>
				<Text href='/privacy-policy.html'
				 style={{ color: 'grey', textAlign: 'center', margin: 5 }}>
					{`Politique de confidentialité`}
				</Text>

				<Text style={{ color: 'grey', textAlign: 'center', margin: 5 }}>
					Copyright {new Date().getFullYear()}
				</Text>

				<Text
					href="mailto:contact.ipronostic@gmail.com"
					target="_blank"
					style={{ color: 'grey', textAlign: 'center', margin: 5 }}>
					{`contact.ipronostic@gmail.com`}
				</Text>
			</View>


			<View style={[{ flex: 1, justifyContent: 'center', margin: 5 }]}>
				<IPronostic style={{ height: 15 }} />
			</View>
		</View>
	);
};