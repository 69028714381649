import React from "react"
import Text from "./Text"
import { View } from "react-native"
import { colors, styles } from "../res";
import use from "rn-tools/hook"

/**
 * @param {*}
 */
export default function ErrorView({ message, retry, ...props }) {
	props.style = use.style(props.style, localStyles.layout);

	return (
		<View {...props}>
			<Text style={localStyles.message}>
				{message === undefined ? "Une erreur s'est produite" : message}
			</Text>

			{
				retry &&
				<Text onPress={() => retry()} style={localStyles.retry}>
					Recharger
				</Text>
			}
		</View>
	);
}

const localStyles = {
	layout: {
		...styles.center,
	},

	message: {
		textAlign: styles.textAlign.center,
		marginBottom: 10,
	},

	rety: {
		color: colors.accent(1),
	}
};