import React from "react";
import { View } from "react-native";
import use from "rn-tools/hook";
import renderMobile from "./mobile";
import renderTablet from "./tablet";

// TODO: remake the layout
// the layout must follow the note on OneNote
// Each piece of the layout in the note should be a component (like TeamLogo)
// And each responsive layout of the note should be a private component of the header
// So the header will only have to select the layout according to the responsive level

function MatchHeader({ ...props }) {
	const [level, onLayout] = use.responsive(levels);
	props.onLayout = onLayout;

	const ResponsiveComponent = level > levels.mobile ? renderTablet
			: level > 0 ? renderMobile
				: () => <View onLayout={onLayout} />; // default

	return <ResponsiveComponent {...props} />;
}

export default React.memo(MatchHeader);


const levels = {
	mobile: 760,
};