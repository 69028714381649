import { ViewStyle } from "react-native";

const styles = {
	maxAppWidth: 1024,

	flexDirection: {
		row: "row",
		column: "column",
		rowReverse: "row-reverse",
		columnReverse: "column-reverse",
	},

	flexWrap: {
		wrap: "wrap",
		nowrap: "nowrap",
	},

	alignContent: {
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		spaceBetween: "space-between",
		spaceAround: "space-around",
	},

	alignItems: {
		auto: "auto",
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		baseline: "baseline",
	},

	alignSelf: {
		auto: "auto",
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		stretch: "stretch",
		baseline: "baseline",
	},

	justifyContent: {
		flexStart: "flex-start",
		flexEnd: "flex-end",
		center: "center",
		spaceBetween: "space-between",
		spaceAround: "space-around",
		spaceEvenly: "space-evenly",
	},

	position: {
		absolute: "absolute",
		relative: "relative",
	},

	overfow: {
		auto: "auto",
		hidden: "hidden",
		visible: "visible",
	},

	textAlign: {
		auto: 'auto',
		left: 'left',
		right: 'right',
		center: 'center',
		justify: 'justify',
	},

	fit: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	} as {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},

	center: {
		alignItems: "center",
		justifyContent: "center",
	} as ViewStyle,

	square: (size) => ({
		height: size,
		width: size,
	}),

	circle: (size) => ({
		...styles.square(size),
		borderRadius: size / 2,
	}),

	absolute: (positions) => ({
		position: 'absolute',
		...(positions || {}),
	})
};

export default styles;