import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { Image, Pressable, View } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import Portal from "rn-tools/component/Portal";
import use from "rn-tools/hook";
import useUser from "rn-tools/hook/useUser";
import useDBUser from "../hook/useDBUser";
import { colors, images, styles } from "../res";
import screens from "../screens";
import AppName from "./AppName";
import MaterialIcon from "./icon/Material";
import Text from "./Text";
import IPronostic from "./IPronostic";

function AppBar({ route, navigation, ...props }) {
	const user = useUser();
	const dbUser = useDBUser();
	const [authenticating, setAuthenticating] = use.state(false);

	use.effect(() => {
		if (authenticating && dbUser) {
			setAuthenticating(false);
			navigation.navigate(screens.user, { id: dbUser.id });
		}
	}, [authenticating, dbUser]);

	// TODO replace user pic with 3-dots options when user is on his profile (check route)

	const isCurrentUserProfileUI = (
		route.name === screens.user
		&& dbUser
		&& /* string */ route.params?.id == /* number */dbUser?.id
	);

	const isSearchUI = route.name === screens.search;

	props.style = use.style(props.style, localStyles.layout);

	return (
		<View {...props}>
			<View style={localStyles.container}>

				<View style={localStyles.appNameWrapper}>
					<Portal to={screens.matchList}>
						<AppName />
					</Portal>
				</View>

				<Portal to={screens.search}>
					<View style={localStyles.search}>
						{
							!isSearchUI &&
							<MaterialIcon
								name="search"
								color="white" size={25} />
						}
					</View>
				</Portal>

				<View style={localStyles.profile}>
					{
						authenticating ?
							<LoadingPlaceholder
								animated
								style={styles.circle(25)} />

							: (user !== undefined) && (
								!user ?
									<Pressable
										onPress={() => navigation.push(screens.authModal)}
										// onPress={() => {
										// 	setAuthenticating(true);
										// 	authenticate()
										// 		.catch(() => setAuthenticating(false));
										// }}
										style={localStyles.action}>
										<Text>
											{`Rejoindre ›`}
										</Text>
									</Pressable> :
									dbUser && (
										<Pressable onPress={() => navigation.push(screens.profileModal)}>
											<View style={localStyles.action}>
												<Image
													key={user?.uid}
													source={user && { uri: user.photoURL }}
													defaultSource={images.user}
													resizeMode="cover"
													style={localStyles.userPicture}
												/>
											</View>
										</Pressable>
									)
							)
					}
				</View>
			</View>
		</View>
	);
}

export default React.memo(AppBar);

function authenticate() {
	const provider = new GoogleAuthProvider();
	// provider.addScope('email');
	return signInWithPopup(getAuth(), provider);
};

const localStyles = {
	layout: {
		minHeight: 56,
		paddingHorizontal: 16,
		backgroundColor: colors.background(0),
		borderBottomWidth: .5,
		borderBottomColor: colors.border(0),
		alignItems: styles.alignItems.center,
		zIndex: 1,
	},

	container: {
		maxWidth: styles.maxAppWidth,
		width: "100%",
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceBetween,
		alignItems: styles.alignItems.center,
		flexGrow: 1,
	},


	appNameWrapper: {
		...styles.fit,
		...styles.center,
	},

	search: {
		...styles.center,
		...styles.absolute({ left: 0, top: 0, bottom: 0 }),
	},

	profile: {
		...styles.center,
		...styles.absolute({ right: 0, top: 0, bottom: 0 }),
	},

	userPicture: {
		height: 24,
		width: 24,
		borderRadius: 12,
		borderWidth: .5,
		borderColor: "white",
	}
};