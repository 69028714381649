import React from "react";
import { ScrollView } from "react-native";
import HeadSection from "./HeadSection";
import MatchesSection from "./MatchesSection";
import ProfileSection from "./ProfileSection";
import BetSection from "./BetSection";
import Footer from "./Footer";
import { TextStyleContext } from "rn-tools/component/Text"
import use from "rn-tools/hook";
import { styles } from "../../res";
import HomeContext from "./context";

function HomeUI({ ...props }) {
	const [level, onLayout, { select }] = use.responsive(levels, 1);
	const sharedStyles = use.memory(() => ({
		title: {
			fontSize: select({
				Infinity: 40,
				default: '7vw',
			}),
			fontWeight: 'bold',
			textAlign: 'center',
		},

		content: {
			fontSize: select({
				Infinity: 23,
				default: '4vw',
			}),
			textAlign: 'justify',
		},
		select,
	}), [level]);


	return (
		<HomeContext.Provider value={sharedStyles}>
			<TextStyleContext.Provider value={defaultTextStyle}>
				<ScrollView
					onLayout={onLayout}
					style={{ flex: 1 }}
					{...props}>
					<HeadSection />
					<MatchesSection />
					<ProfileSection />
					<BetSection />
					<Footer />
				</ScrollView>
			</TextStyleContext.Provider>
		</HomeContext.Provider>
	);
}

const levels = {
	mobile: 390,// text align center
	tablet: 550,
};

export default React.memo(HomeUI);

const defaultTextStyle = {
	textShadowColor: 'rgba(0, 0, 0, 0.75)',
	textShadowOffset: { width: 0, height: 2 },
	textShadowRadius: 13,
	lineHeight: '140%'
};