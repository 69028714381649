import "./@types/react-native"
import React from 'react';
import ReactDOM from 'react-dom';
import "js-tools/monkey"
import App from './App';
import "./res/globalSyle.css"
import "firebase/auth"

ReactDOM.render(
	<App />,
	document.getElementById('root'),
);