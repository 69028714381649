// Must be before every other imports
import "./init.js";
// ---
import { ApolloProvider } from '@apollo/client';
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import AppBar from "@src/component/AppBar";
import ProfileModal from "@src/modal/ProfileModal";
import { colors, styles } from "@src/res";
import screens, { linking } from "@src/screens";
import EditPronosticUI from "@src/ui/EditPronosticUI";
import MatchesListUI from "@src/ui/MatchesListUI";
import MatchUI from "@src/ui/MatchUI";
import PronosticUI from "@src/ui/PronosticUI";
import UserUI from "@src/ui/UserUI";
import "firebase/auth";
import { T } from "ramda";
import { useState } from 'react';
import { View } from "react-native";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useCallback from 'rn-tools/hook/useCallback';
import useEffect from 'rn-tools/hook/useEffect';
import AuthModal from "../modal/AuthModal";
import HomeUI from "../ui/HomeUI";
import SearchUI from "../ui/SearchUI";
import apolloClient from "./apollo";

const stack = createStackNavigator();

export default function App() {
	useEffect(() =>
		setTimeout(() => {
			const removeFadingStyle = addFadeSplashScreenStyle();
			const splashScreen = document.getElementById('splash');
			splashScreen.classList.add('splash_screen_fade_out');
			setTimeout(() => {
				splashScreen.remove();
				removeFadingStyle();
			}, 600);
		})
	);

	const [navigation, setNavigation] = useState();
	// https://github.com/react-navigation/react-navigation/issues/9213
	const setNavigationRef = useCallback(instance => {
		if (instance) {
			instance.isFocused = T;
			setNavigation(instance);
		}
	},
		[Boolean(navigation)]
	);


	return (
		<ApolloProvider client={apolloClient}>
			<NavigationContainer
				ref={navigation ? undefined : setNavigationRef}
				theme={THEME}
				linking={linking}
				documentTitle={{ enabled: false }}>

				{/* This absolute fixed wrapper allows the app bar to stick */}
				<View style={styles.fit}>

					<stack.Navigator
						initialRouteName={screens.home}
						screenOptions={{ header: props => <AppBar {...props} /> }}>

						<stack.Group>
							<stack.Screen name={screens.home} component={HomeUI} />
							<stack.Screen name={screens.search} component={SearchUI} />
							<stack.Screen name={screens.matchList} component={MatchesListUI} />
							<stack.Screen name={screens.user} component={UserUI} />
							<stack.Screen name={screens.match} component={MatchUI} />
							<stack.Screen name={screens.pronostic} component={PronosticUI} />
							<stack.Screen name={screens.editPronostic} component={EditPronosticUI} />
						</stack.Group>

						<stack.Group screenOptions={MODAL_OPTIONS}>
							<stack.Screen name={screens.authModal} component={AuthModal} />
							<stack.Screen name={screens.profileModal} component={ProfileModal} />
						</stack.Group>

					</stack.Navigator>
				</View>

				<ToastContainer
					position="bottom-right"
					hideProgressBar
					toastStyle={localStyles.toast}
					closeButton={false}
					closeOnClick={false} />
			</NavigationContainer>
		</ApolloProvider>
	);
}

const MODAL_OPTIONS = {
	presentation: 'transparentModal',
	headerShown: false,
	cardOverlayEnabled: true,
	animationEnabled: true,
};

const THEME = {
	...DefaultTheme,
	dark: true,
	colors: {
		...DefaultTheme.colors,
		primary: colors.accent(0),
		background: colors.background(1),
		card: 'transparent',
		text: 'white',
		border: 'lightgrey',
		notification: colors.accent(1),
	},
};

function addFadeSplashScreenStyle() {
	const styleElement = document.createElement('style');
	styleElement.innerText = `
		@keyframes fadingOut {
			from {opacity: 1}
			to {opacity: 0}
		}
		.splash_screen_fade_out {
			animation-name: fadingOut;
			animation-duration: 600ms;
			animation-fill-mode: forwards;
	}`;
	document.head.appendChild(styleElement);

	return () => styleElement.remove();
}

const localStyles = {
	toast: {
		backgroundColor: 'black',
		color: 'white',
	}
};