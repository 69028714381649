import { gql, useMutation } from "@apollo/client";
import "firebase/storage";
import React, { useState } from "react";
import { TextInput, TextStyle, View, ViewProps } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import { Nilable } from "../../@types/utils";
import { User } from "../../generated/graphql";
import { colors, strings } from "../../res";
import Text from "../Text";
const use = require("rn-tools/hook").default;
const useUser = require("rn-tools/hook/useUser").default;


export default function NameAndPseudo({ user, editable, textsStyle, startEdit, ...props }: NameAndPseudoProps) {
	const loading = !user;
	const currentUser = useUser();
	editable = editable && currentUser && user && user.firebaseId === currentUser.uid;

	let [editedName, setEditedName] = useState<string | undefined>(user?.name);
	if (editedName === undefined)
		editedName = user?.name;

	const isEditedNameValid = isNameValid(editedName);
	const isNameEdited = editedName !== user?.name;

	const [persistName, { loading: persistingName }] = useMutation(PERIST_NAME_GQL);

	function persistEditedName() {
		if (isEditedNameValid && isNameEdited && user)
			persistName({
				variables: {
					userId: user.id,
					name: editedName,
				}
			});
	}

	const autoFocusName = startEdit === 'name';
	const [nameFocused, setNameFocused] = use.state(false);


	return (
		<View {...props}>

			<LoadingPlaceholder
				animated={loading}
				style={[localStyles.name.layout(loading)]}>

				{
					editable ?
						<TextInput
							key={String(autoFocusName)}
							defaultValue={user?.name}
							autoFocus={autoFocusName}
							value={editedName}
							onChangeText={setEditedName}
							onSubmitEditing={persistEditedName}
							editable={!persistingName}
							selectTextOnFocus={!isNameEdited}
							onFocus={() => setNameFocused(true)}
							onBlur={() => setNameFocused(false)}
							style={[localStyles.name.input(isNameEdited, isEditedNameValid, persistingName, nameFocused), textsStyle]} /> :

						<Text
							numberOfLines={1}
							style={[localStyles.name.text, textsStyle]}>
							{user?.name}
							{strings.invisible}
						</Text>
				}
			</LoadingPlaceholder>

			<LoadingPlaceholder
				animated={loading}
				style={[localStyles.pseudo.layout]}>
				<Text
					numberOfLines={1}
					style={[localStyles.pseudo.text, textsStyle]}>
					{user && '@'}{user?.pseudo}
					{strings.invisible}
				</Text>
			</LoadingPlaceholder>
		</View>
	);
}

export interface NameAndPseudoProps extends ViewProps {
	user: Nilable<User>,
	editable: any,
	textsStyle: TextStyle,
	startEdit?: "name" | undefined,
}



const isNameValid = Boolean;

const PERIST_NAME_GQL = gql`
	mutation persistUserName ($name: String!, $userId: String!){
		update_user_by_pk(pk_columns: {id: $userId}, _set: {name: $name}) {
			id
			name
		}
	}
`;

const localStyles = {
	name: {
		layout: loading => ({
			minWidth: loading ? 100 : undefined,
			marginBottom: 7,
			maxWidth: "100%",
			borderRadius: 5,
		}),

		text: {
			fontSize: 25,
			fontWeight: "bold",
			color: 'white',
			borderBottomWidth: 1,
			borderColor: 'transparent',
		},

		input(isEdited, valid, persisting, focused): TextStyle {
			return {
				fontSize: 25,
				fontWeight: "bold",
				color: persisting ? 'grey' : 'white',
				borderBottomWidth: 1,
				borderColor: !focused && !isEdited ? 'transparent' :
					!valid ? 'red' :
						'white',
				cursor: isEdited ? 'auto' : 'pointer',
			};
		},
	},

	pseudo: {
		layout: {
			minWidth: 65,
			maxWidth: "100%",
			borderRadius: 5,
		},

		text: {
			fontSize: 16,
			color: colors.link(1),
		}
	},
};