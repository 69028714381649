import React, { useContext } from "react";
import use from "rn-tools/hook";
import Text from '../../../component/Text';
import ShortMatchesList from '../../../component/ShortMatchesList';
import HomeContext from "../context";
import Section from "../Section";

function MatchesSection({ ...props }) {
	const context = useContext(HomeContext);
	const [, onLayout, { select }] = use.responsive(levels);
	props.onLayout = onLayout;

	return (
		<Section
			background={require('./background.jpg')}
			foreground="#00000020"
			innerStyle={{ justifyContent: 'center', paddingLeft: 20, paddingRight: 10 }}
			{...props}>

			<Text style={[
				context.title,
				{ marginBottom: 20 },
				select({ mobile: { textAlign: 'center' } }),
			]}>
				{`Pronostiquez les matches`}
			</Text>

			<Text
				style={[
					context.content,
					{ marginBottom: 50 },
					select({ mobile: { textAlign: 'center' } }),
				]}>
				{`Commencez dés maintenant à pronostiquer les matches à venir.\nMais attention ! Au coup d’envoi, il n'est plus possible de modifier votre pronostique.`}
			</Text>


			<Text
				style={[
					context.content,
					{ marginBottom: 20 },
					select({ mobile: { textAlign: 'center' } }),
				]}>
				{`Les prochains matches`}
			</Text>

			<ShortMatchesList limit={
				select({
					default: 6,
					mobile: 3
				})
			} />

			
		</Section>
	);
}

export default React.memo(MatchesSection);

const levels = {
	mobile: 650
};

const commonStyles = {
	text: {
	},
}