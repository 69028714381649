import React from "react"
import Text from "./Text"
import { View } from "react-native"
import { colors, styles } from "../res";
import is from "js-tools/is"
import use from "rn-tools/hook";

/**
 * Score For now accepts -999 to 9999
 */
export default function ScoreView({ score, ...props }) {
	if (is.defined(score))
		score = Math.trunc(Number(score)) || 0;

	props.style = use.style(props.style, localStyles.layout, [score]);

	return (
		<View {...props}>
			{
				score?.toFixed(0)
					.split('')
					.reverse()
					.reduce((table, number, index) => {
						if (!(index % 2)) // new row
							table.push([0, 0]);
						table.last[index % 2] = number;
						return table;
					}, [])
					.map(row => row.reverse())
					.reverse()
					.map((array, row, table) => (
						<View key={row} style={localStyles.row}>
							{
								array.map((number, column) =>
									(number || (table.length > 1)) && // for a score = 0, display only 0
									<Text
										key={column}
										style={localStyles.number(score, (1 - column) + (table.length - 1 - row) * 2 < score.toString().length)}>
										{number}
									</Text>
								)
							}
						</View>
					))
			}
		</View>
	);
}

const SIZE = ScoreView.SIZE = 75;

const localStyles = {
	layout: score => ({
		borderWidth: 1,
		borderColor: score > 0 ? colors.accent(0) :
			score < 0 ? colors.bad(0) :
				colors.neutral(1),
		borderRadius: 5,
		justifyContent: styles.justifyContent.spaceEvenly,
		...styles.square(SIZE),
	}),

	row: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceEvenly,
	},

	number: (score, visible) => ({
		color: score > 0 ? colors.accent(0) :
			score < 0 ? colors.bad(0) :
				colors.neutral(1),
		fontSize: 25,
		opacity: visible ? 1 : 0,
		fontFamily: 'Exo 2, serif',
	})
};