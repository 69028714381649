import { useNavigation } from '@react-navigation/native';
import { useCardAnimation } from '@react-navigation/stack';
import React from 'react';
import { Animated, Pressable, ScrollView, View, Dimensions } from "react-native";
import useResponsive from 'rn-tools/hook/useResponsive.ts';
import useStyle from 'rn-tools/hook/useStyle';
import { colors, styles } from "../res";


function ResponsiveModal({ children, style, ...props }) {
	const navigation = useNavigation();

	const levels = { mobile: 600 };
	const [level, onLayout] = useResponsive(levels, Dimensions.get('window'));
	const isMobile = level === levels.mobile;
	props.onLayout = onLayout;

	const { current } = useCardAnimation();
	const modalStyle = useStyle(style, localStyles.modal(current.progress, isMobile), [current, isMobile]);

	return isMobile ? (
		<View {...props} style={{ flex: 1, opacity: level ? 1 : 0 }}>
			<ScrollView
				contentContainerStyle={{ flexGrow: 1 }}
				style={localStyles.scroll}>
				<Pressable
					style={{
						flexGrow: 1,
						minHeight: 300,
					}}
					onPress={navigation.goBack}
				/>

				{/* TODO NEXT: in desktop, the scrollview should be the one animated */}
				<Animated.View
					style={modalStyle}>
					{children}
				</Animated.View>
			</ScrollView>
		</View>
	) : (
		<View {...props} style={[{ flex: 1 }, styles.center]}>
			<Pressable
				style={[styles.fit, { zIndex: -1 }]}
				onPress={navigation.goBack}
			/>
			<View style={modalStyle}>
				{children}
			</View>
		</View>
	);
}

export default React.memo(ResponsiveModal);

const localStyles = {
	scroll: {
		flex: 1,
	},

	modal: (av, isMobile) => {
		return Object.assign({
			zIndex: 0,
			padding: 20,
			backgroundColor: colors.background(3),
			borderWidth: .5,
			borderColor: 'grey',

			transform: [
				{
					translateY: av.interpolate({
						inputRange: [0, 1],
						outputRange: [500, 0],
						extrapolate: 'clamp',
					}),
				},
			],
		},
			isMobile ? {
				borderTopLeftRadius: 20,
				borderTopRightRadius: 20,
				borderBottomWidth: 0,
			} : /* Desktop */ {
				borderRadius: 15,
			}
		);
	},
}