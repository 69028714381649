import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { offsetLimitPagination } from "@apollo/client/utilities";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as GraphQLErrors from "ipronostic.common/src/GraphQLErrors";
import distinctBy from "js-tools/array/distinctBy";
import { eqProps, pipe } from 'ramda';
import onceAuthReady from "rn-tools/utils/onceAuthReady";

const infiniteList = {
	keyArgs(args) {
		const KEYS_ARGS = ['order_by', 'where'];
		return Object.keys(args || {}).filter(arg => KEYS_ARGS.includes(arg));
	},

	// merge(existing = [], incoming, { args: { offset = 0 } }) {
	// 	const result = existing.slice();
	// 	incoming.forEach((item, i) => result[i + offset] = item);
	// 	console.log(incoming);
	// 	return distinctBy(eqProps('__ref'), result); // remove duplicates
	// },

	merge: pipe(
		offsetLimitPagination().merge,
		distinctBy(eqProps('__ref')),
	),

	// reading from the cache will return the entire list 
	// (still matching the sort, filters and other params), 
	// even if the limit parameter is set.
	// So for use cases which needs a limited amount of items, 
	// (like for the matches list section on the home presentation page)
	// they should slice the result
};

export const cache = new InMemoryCache({
	typePolicies: {
		query: {
			queryType: true,
			fields: {
				match: infiniteList,
				user: infiniteList,
			},
		},

		match: {
			fields: {
				pronostics: infiniteList
			}
		},

		user: {
			fields: {
				pronostics: infiniteList
			}
		},
	},
});

const apollo = new ApolloClient({
	defaultOptions: {
		watchQuery: {
			// fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			// fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	},

	cache,

	link: from([
		onError(({ response, graphQLErrors, networkError, operation, forward }) => {
			const user = getAuth().currentUser;
			const context = operation.getContext();

			if (user && graphQLErrors?.some(error => error.extensions.code === GraphQLErrors.refreshToken.extensions.code) && !context.tokenRefresh) {
				context.tokenRefresh = true;
				operation.setContext(context);

				// retry the request with new token loaded
				return forward(operation);
			}

			return new Observable(observer => observer.error(response ? response.errors[0] : networkError));
		}),

		setContext(async (request, context) => {
			await onceAuthReady();
			const user = getAuth().currentUser;

			if (user) {
				if (context.tokenRefresh)
					await new Promise(r => setTimeout(r, 3000));

				const idToken = await user.getIdToken(context.tokenRefresh);
				context.headers = {
					Authorization: `Bearer ${idToken}`,
				};

				return context;
			}
		}),

		new HttpLink({
			uri: "https://europe-west1-ipronostic-58c42.cloudfunctions.net/graphql",
			// uri: "http://localhost:5000/ipronostic-58c42/europe-west1/graphql",
		}),
	])
});

export default apollo;

onAuthStateChanged(getAuth(), () => apollo.resetStore());