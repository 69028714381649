import { getAuth, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth';
import React from "react";
import {
	Image, Pressable, TextStyle, View, ViewStyle
} from 'react-native';
import Portal from "rn-tools/component/Portal";
import useUser from "rn-tools/hook/useUser";
import MaterialIcon from "../component/icon/Material";
import ResponsiveModal from "../component/ResponsiveModal";
import Text from "../component/Text";
import useDBUser from "../hook/useDBUser";
import { colors, images, styles } from "../res";
import screens from "../screens";
import { ProdileUiEditParam } from '../ui/UserUI/params';

function AuthModal({ navigation }) {
	const dbUser = useDBUser();

	const goToProfile = (params = {}) => navigation.navigate(screens.user, { id: dbUser.id, ...params });
	return (
		<ResponsiveModal style={localStyles.layout}>
			<UserHeader />

			{SEPARATOR}

			{
				[
					{
						title: `Mon profile`,
						subtitle: `Se diriger vers votre page profile utilisateur`,
						onPress: () => goToProfile(),
					},
					{
						title: `Changer ma photo de profil`,
						subtitle: `Importez une image qui vous représente.`,
						onPress: () => goToProfile({ edit: ProdileUiEditParam.picture }),
					},
					{
						title: `Modifier mon nom d'utilisateur`,
						subtitle: `Ecrivez votre nom d'utilisateur publique.`,
						onPress: () => goToProfile({ edit: ProdileUiEditParam.name })
					},
					{
						title: `Nous contacter`,
						subtitle: `Envoyez nous un email`,
						onPress: () => window.open(`mailto:contact.ipronostic@gmail.com`, '_blank'),
					},
					// {
					// 	title: `Modifier mon pseudo`,
					// 	subtitle: `Selectionnez un pseudo unique pour votre profile.`,
					// },
					{
						title: `Me déconnecter`,
						icon: false,
						onPress: () => {
							navigation.replace(screens.home);
							signOut(getAuth());
						}
					},
				].map(({ title, subtitle, onPress, icon = true }, index) => (
					<Pressable
						key={title}
						onPress={onPress}
						style={{ flexDirection: "row", alignItems: 'center', borderTopWidth: index ? 1 : 0, borderColor: 'grey', paddingVertical: 15 }}>
						<View style={{ flex: 1 }}>
							<Text style={{ fontWeight: "bold", fontSize: 20, marginBottom: 7 }}>
								{title}
							</Text>

							{
								Boolean(subtitle) &&
								<Text style={{ fontStyle: 'italic', color: 'lightgrey' }}>
									{subtitle}
								</Text>
							}
						</View>

						{
							icon &&
							<MaterialIcon
								name="chevron-right"
								size={30}
								color="white" />
						}
					</Pressable>
				))
			}

		</ResponsiveModal>

	);
}

export default React.memo(AuthModal);

function UserHeader() {
	const dbUser = useDBUser();
	const user = useUser();
	return (
		<Portal to={screens.user} with={{ id: dbUser?.id }
		} disabled={!dbUser}>
			<View>
				<View style={localStyles.userHeader}>
					<Image
						source={{ uri: user?.photoURL || undefined }}
						defaultSource={images.user}
						resizeMode="cover"
						style={localStyles.userPicture}
					/>

					<Text style={localStyles.userName}>
						{user?.displayName || `Profile`}
					</Text>
				</View>

				< Text style={{ color: 'grey', fontStyle: 'italic', marginTop: 10, textAlign: 'center' }}>
					{user ? '' : `Connectez vous via\nun des moyens ci-dessous`}
				</Text>
			</View>
		</Portal>
	);
}

const SEPARATOR = (
	<View style={{
		height: .5,
		backgroundColor: "white",
		alignSelf: 'center',
		marginVertical: 30,
		width: '50%',
	}} />
);

export const LOCAL_EMAIL_KEY = "email_to_signin";

export function checkAuthURL() {
	const email = localStorage.getItem(LOCAL_EMAIL_KEY);
	if (email) {
		const auth = getAuth();
		const currentURL = window.location.href;
		if (isSignInWithEmailLink(auth, currentURL)) {
			signInWithEmailLink(auth, email, currentURL)
				.catch(console.error);
		}

		localStorage.removeItem(LOCAL_EMAIL_KEY); // clean
	}
}

const localStyles = {
	layout: {
		padding: 40,
		maxWidth: 600,
	},

	userHeader: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	} as ViewStyle,

	userPicture: {
		...styles.circle(40),
		marginRight: 20,
		borderWidth: 1,
		borderColor: 'white',
	},

	userName: {
		fontWeight: 'bold',
		fontSize: 20,
	},


	email: {
		height: 50,
		borderRadius: 25,
		borderColor: colors.accent(0),
		borderWidth: 1,
		flexDirection: 'row',
		marginBottom: 20,
		width: '100%',
		maxWidth: 300,
		justifyContent: 'center'
	},

	emailIcon: {
		alignSelf: 'center',
		marginLeft: 15,
	},

	emailInput(ok: boolean): TextStyle {
		return {
			flex: 1,
			paddingLeft: 10,
			paddingRight: 5,
			fontWeight: 'bold',
			color: ok ? 'white' : 'red',
			cursor: 'pointer',
		};
	},

	emailSubmit: {
		...styles.center,
		backgroundColor: colors.accent(1),
		height: 50,
		width: '100%',
		maxWidth: 300,
		borderRadius: 10,
	},

	emailSubmitTxt: {
		fontWeight: 'bold',
		fontSize: 18,
	},

	google: {
		width: '100%',
		maxWidth: 300,
	},

	openMailBox: {
		...styles.center,
		borderColor: colors.accent(1),
		height: 50,
		width: '100%',
		maxWidth: 300,
		borderRadius: 10,
		marginTop: 20,
	},

	openMailBoxText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: colors.accent(1),
	}
};