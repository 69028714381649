import React from 'react'
import { View } from 'react-native'
import LoadingPlaceholder from 'rn-tools/component/LoadingPlaceholder'
import TextHolder from '@src/component/TextHolder'
import MatchView from "@src/component/MatchView"
import MatchScores from "@src/component/MatchScores"
import Text from "@src/component/Text"
import Material from "@src/component/icon/Material"
import use from "rn-tools/hook"
import { styles } from '../../res'

function MatchReview({ item: match, ...props }) {

	props.style = use.style(props.style, localStyles.layout);
	
	// TODO remove this temp solution for emptiness
	return <MatchView match={match} {...props} />;
	
	const userPronostic = match?.userPronostics[0];

	return (
		<View {...props}>
			<MatchView match={match} style={localStyles.match} />

			{
				(!match || match.pronosticsStats?.length > 0) &&
				<>
					{SEPARATOR}
					{// TODO HERE loading placeholder
						(match?.pronosticsStats || [null, null, null])
							.slice(0, userPronostic ? 2 : 3)
							.map((stats, index) =>
								<Row
									key={index}
									scores={stats}
									label={`${stats?.numberOfPronostics} pronostics`} />
							)
					}

					{
						userPronostic &&
						<Row
							key={2}
							scores={userPronostic}
							label={
								<Text style={{ fontWeight: 'bold' }}>
									{`Mon pronostic`}
								</Text>
							} />
					}
				</>
			}

			{SEPARATOR}

			<View style={[localStyles.totalNumberOfPronostics.layout, { opacity: match ? 1 : 0 }]}>
				<Text style={localStyles.totalNumberOfPronostics.text}>
					{match?.numberOfPronostics}
					{` pronostics`}
				</Text>

				<Material name='chevron-right' size={30} color="white" />
			</View>


		</View>
	);
}

function Row({ scores, label }) {
	return (
		<View style={localStyles.pronosticsRow.layout}>
			<View style={localStyles.pronosticsRow.cell}>
				<MatchScores
					scores={scores}
					style={localStyles.pronosticsRow.scores} />
			</View>

			<View style={localStyles.pronosticsRow.cell}>
				<LoadingPlaceholder
					animated={!scores}
					Component={Text}
					style={localStyles.pronosticsRow.number(scores)}>
					{scores ? label : <TextHolder for="100000 pronostics" />}
				</LoadingPlaceholder>
			</View>
		</View>
	);
}

const SEPARATOR = <View style={{ alignSelf: 'center', width: 100, height: 1, backgroundColor: 'grey', marginVertical: 30 }} />

export default React.memo(MatchReview);

const localStyles = {
	layout: {
		borderWidth: 1,
		borderColor: 'grey',
		borderRadius: 8,
		paddingHorizontal: 10,
		paddingVertical: 30,
	},

	head: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},

	match: {
		maxWidth: styles.maxAppWidth * .75,
		width: '90%',
		alignSelf: 'center',
	},

	pronosticsRow: {
		layout: {
			flexDirection: 'row',
			alignItems: 'center',
			marginVertical: 10,
		},

		cell: {
			alignItems: 'center',
			flex: 1,
		},

		number: ready => ({
			fontSize: 25,
			borderRadius: ready ? 0 : 5,
			textAlign: 'center',
		}),

		scores: {
			textAlign: 'center',
		}
	},

	totalNumberOfPronostics: {
		layout: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'flex-start',
		},

		text: {
			fontSize: 25,
			marginRight: 5,
		},
	},
};