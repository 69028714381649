import React, { useContext } from "react";
import { Image, View } from "react-native";
import use from "rn-tools/hook";
import Portal from "rn-tools/component/Portal";
import Text from '../../../component/Text';
import Section from "../Section";
import HomeContext from "../context"
import screens from "../../../screens"

function HeadSection({ ...props }) {
	const [, onLayout, { select }] = use.responsive(levels);
	props.onLayout = onLayout;

	const context = useContext(HomeContext);

	props.style = use.style(props.style, { paddingBottom: 50 });

	const responsiveTextStyle = select({
		Infinity: {
			textAlign: 'left',
		},
	});

	return (
		<Section
			background={require('./background.jpg')}
			foreground="#00000040"
			innerStyle={{ justifyContent: 'center' }}
			{...props}>

			<View style={select({
				default: {
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingHorizontal: 10,
					paddingBottom: 20,
				},
				mobile: {
					alignItems: 'center',
					justifyContent: 'center',
				},
			})}>
				<Image
					source={require('./demo.png')}
					resizeMode="contain"
					loading='lazy'
					style={[
						select({
							default: {
								width: '48%',
								maxWidth: 400,
								height: 400,
							},

							mobile: {
								width: '90%',
								height: 250,
								marginBottom: 40,
							},
						})
					]} />



				<View style={select({
					default: {
						width: '48%',
					},
					mobile: {
						width: '90%',
						maxWidth: 540,
					}
				})}>
					<Text style={[
						context.title,
						{ marginBottom: 50 },
						responsiveTextStyle,
					]}>
						{`Une plateforme de pronostiques ouverte`}
					</Text>

					<Text
						style={[
							select({
								default: { fontSize: 30 },
								mobile: { fontSize: '4vw' }
							})
						]}>
						{`Vous connaissez vos équipes et vous êtes certain de la fin du match?\nPronostiquez le et faites évoluer votre score.`}
						<Text style={{ fontWeight: 'normal' }}>
							{`\nLa plateforme est entièrement gratuite et ouverte.`}
						</Text>
					</Text>
				</View>
			</View>

			<Portal to={screens.matchList}>
				<Text style={{ textAlign: 'center', padding: 30, fontSize: 23, fontWeight: 'bold', paddingVertical: 20 }}>
					{`Entrer ›`}
				</Text>
			</Portal>
		</Section>

	);
}

export default React.memo(HeadSection);

const levels = { mobile: 770 };