import { initializeApp } from 'firebase/app';
import moment from "moment"
import 'moment/locale/fr'
import onceAuthReady from 'rn-tools/utils/onceAuthReady';
import { checkAuthURL } from '../modal/AuthModal';

moment.locale('fr');

initializeApp({
	apiKey: "AIzaSyBIv23IYvsSG-0dK_4dqCJTc-a1YM2lxqg",
	authDomain: "ipronostic-58c42.firebaseapp.com",
	projectId: "ipronostic-58c42",
	storageBucket: "ipronostic-58c42.appspot.com",
	messagingSenderId: "474551385180",
	appId: "1:474551385180:web:73b2dac8b5250d9c6d7a99",
	measurementId: "G-G89TBW40JR"
});

onceAuthReady().then(checkAuthURL);