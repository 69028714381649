import { gql, useMutation } from "@apollo/client"
import { useIsFocused } from '@react-navigation/native'
import MatchHeader from "@src/component/MatchHeader"
import Text from "@src/component/Text"
import { colors } from "@src/res"
import React from 'react'
import { ScrollView, TextInput, TouchableOpacity, View as RNView } from "react-native"
import use from "rn-tools/hook"
import useQueryLoader from "rn-tools/hook/useQueryLoader"
import useUser from 'rn-tools/hook/useUser'
import MaterialIcons from '../component/icon/Material'
import MaterialCommunityIcons from '../component/icon/MaterialCommunity'
import screens from '../screens'

function EditPronosticUI({ navigation, route, ...props }) {
	const user = useUser();

	// redirect to authentication if no user
	const matchId = route.params?.matchId;
	const isFocused = useIsFocused();
	use.effect(() => {
		if (user === null && isFocused)
			navigation.replace(screens.match, /* come back here then */ { id: matchId });
	}, [user]);


	// load data
	const { data } = useQueryLoader(user && QUERY, { matchId, uid: user?.uid });
	const match = data?.match_by_pk;
	const pronostic = match?.pronostics[0];

	// redirect if match passed or doesn't exist (it will displays error)
	use.effect(() => {
		if (data && isFocused && (!match || new Date(match.time) <= new Date()))
			navigation.replace(screens.match, { id: matchId });
	}, [data]);


	// -- form ---
	let [commentVisible, setCommentVisible] = use.state(false);
	// fields
	let [score1, setScore1] = use.state();
	let [score2, setScore2] = use.state();
	let [comment, setComment] = use.state();
	// set fields once data loaded
	use.syncEffect(() => {
		if (pronostic) {
			if (pronostic.comment)
				setCommentVisible(commentVisible = true);
			setScore1(score1 = pronostic.score1?.toString());
			setScore2(score2 = pronostic.score2?.toString());
			setComment(comment = pronostic.comment?.toString());
		}
	}, [data]);


	// --- submit ---
	const [submitted, setSubmitted] = use.state(false);
	const [persist, persistState] = useMutation(
		pronostic ? MUTATION.update : MUTATION.create, {
		update: pronostic ? /* updating is automatic as long as the node contains its id */ undefined :
			($, { data: { insert_pronostic_one: pronostic } }) => {
				$.modify({
					id: $.identify({ id: matchId, __typename: 'match' }),
					fields: {
						pronostics(pronosticRefs, { storeFieldName, fieldName }) {
							const params = JSON.parse(storeFieldName.slice(`${fieldName}:`.length));
							if (params.where?.user?.firebaseId._eq === user.uid) {
								const newPronosticRef = $.writeFragment({
									data: pronostic,
									fragment: gql`fragment newPronostic on pronostic {${NEW_PRONOSTIC_FRAGMENT}}`
								});

								return [newPronosticRef];
							}

							return pronosticRefs;
						}
					}
				});
			}
	});

	const redirectToMatch = () => navigation.navigate(screens.match, { id: matchId });
	async function submit() {
		setSubmitted(true);

		const valid = validateScore(score1) && validateScore(score2);

		if (valid) {
			const common = { score1, score2, comment: comment || null };
			const variables = pronostic ? { id: pronostic.id, ...common } : { matchId, ...common };
			const { data, error } = await persist({ variables });
			if (data) {
				const newPronostic = data.insert_pronostic_one || data.update_pronostic_by_pk;
				navigation.navigate(screens.pronostic, { id: newPronostic.id });
			}
			else {
				console.error(error);
			}
		}
	}

	// --- delete ---
	const [sendDeleteRequest, deleteState] = useMutation(MUTATION.delete, {
		update($, { data: { delete_pronostic_by_pk: pronostic } }) {
			$.modify({
				id: $.identify(pronostic),
				fields: (_, { DELETE }) => DELETE,
			});
		}
	});
	
	async function remove() {
		try {
			await sendDeleteRequest({ variables: { id: pronostic.id } });
			redirectToMatch();
		}
		catch (error) {
			console.error(error);
		}
	}

	const tasking = deleteState.loading || persistState.loading;

	const memory = use.memory({});
	const setScore2Input = input => memory.score2Input = input;
	const setCommentInput = input => memory.commentInput = input;

	return (
		<RNView style={localStyles.layout}>
			<ScrollView
				contentContainerStyle={localStyles.content}
				style={localStyles.scroll}>

				<MatchHeader
					match={match}
					style={localStyles.matchHeader}
				/>

				{
					match &&
					<>
						{/* Scrores form */}
						<RNView style={localStyles.row}>


							{
								pronostic ?
									<MaterialIcons
										name="delete-outline"
										size={30}
										color={colors.bad(1)}
										onPress={remove}
										style={localStyles.deleteIcon} /> :
									SCORES_SIDE_PLACHOLDER
							}

							<RNView style={localStyles.scoresForm}>
								<TextInput
									maxLength={1}
									defaultValue={score1}
									onChangeText={text => {
										setScore1(text);
										if (text && Number(text) >= 0)
											memory.score2Input?.focus();
									}}
									editable={!tasking}
									selectTextOnFocus
									autoFocus={!pronostic}

									keyboardType="number-pad"
									returnKeyType="next"
									style={[
										localStyles.scoreInput,
										submitted && !validateScore(score1) && { borderColor: colors.bad(0) }
									]} />

								{SCORES_SEPARATOR}

								<TextInput
									ref={setScore2Input}
									maxLength={1}
									defaultValue={score2}
									onChangeText={setScore2}
									editable={!tasking}
									selectTextOnFocus
									onSubmitEditing={
										!commentVisible ? submit :
											(() => memory.commentInput?.focus())
									}

									keyboardType="number-pad"
									returnKeyType={commentVisible ? 'send' : 'next'}
									style={[
										localStyles.scoreInput,
										submitted && !validateScore(score2) && { borderColor: colors.bad(0) }
									]} />
							</RNView>

							{SCORES_SIDE_PLACHOLDER}


						</RNView>

						{/* Comment input */}
						{
							commentVisible ?
								<TextInput
									ref={setCommentInput}
									multiline
									onChangeText={setComment}
									editable={!tasking}
									autoFocus={commentVisible === 'focus'}
									defaultValue={pronostic?.comment}
									onSubmitEditing={submit}

									returnKeyType="send"
									style={localStyles.commentInput} /> :

								<TouchableOpacity
									onPress={() => setCommentVisible('focus')}
									style={localStyles.commentOpener.layout}>

									<MaterialCommunityIcons
										name="file-document-edit-outline"
										size={30}
										color={colors.neutral(0)}
										style={localStyles.commentOpener.icon} />

									<Text style={localStyles.commentOpener.text}>
										{`J'écris un commentaire`}
									</Text>
								</TouchableOpacity>
						}
					</>
				}

			</ScrollView>

			{/* Submit */}
			{
				data && (
					persistState.loading ?
						<Text style={localStyles.submiting}>
							{`Envoi en cours..`}
						</Text> :

						deleteState.loading ?
							<Text style={localStyles.deleting}>
								{`Suppression en cours..`}
							</Text> :

							<TouchableOpacity
								onPress={submit}
								style={localStyles.submit.layout}>
								<Text style={localStyles.submit.text}>
									{`Envoyer`}
								</Text>
							</TouchableOpacity>
				)
			}

		</RNView>
	);
}

export default React.memo(EditPronosticUI);

const QUERY = gql`query EditPronosticUI ($matchId: Int! = -1, $uid: String!) {
	match_by_pk(id: $matchId) {
		id
		time
		league

		team1 {
			logo
			name
		}
		team2 {
			logo
			name
		}

		pronostics(where: {user: {firebaseId: {_eq: $uid}}}) {
			id
			score1
			score2
			comment
		}
	}
}`;

const NEW_PRONOSTIC_FRAGMENT = `id matchId userId score1 score2 comment`;

const MUTATION = {
	create: gql`mutation EditPronosticUI_create($matchId: Int!, $score1: Int!, $score2: Int!, $comment: String) {
		insert_pronostic_one(object: {matchId: $matchId, score1: $score1, score2: $score2, comment: $comment}) {
		  ${NEW_PRONOSTIC_FRAGMENT}
		}
	  }`,

	update: gql`mutation EditPronosticUI_update($id: Int!, $score1: Int!, $score2: Int!, $comment: String) {
		update_pronostic_by_pk(pk_columns: {id: $id}, _set: {score1: $score1, score2: $score2, comment: $comment}) {
		  	id
		  	score1
			score2
			comment
		}
	  }`,

	delete: gql`mutation EditPronosticUI_delete($id: Int!) {
		delete_pronostic_by_pk(id: $id) {
		  id
		}
	  }`
}

const SCORES_SEPARATOR = <RNView style={{ height: 5, width: 15, backgroundColor: colors.neutral(1) }} />
const SCORES_SIDE_PLACHOLDER = <RNView style={{ width: 30 * 2 + 10, flexShrink: 1 }} />;

function validateScore(text) {
	return text && Number(text) >= 0;
}

const localStyles = {
	layout: {
		flex: 1,
	},

	scroll: {
		flexShrink: 1,
		flexGrow: 1,
		maxHeight: 600,
	},

	content: {
		alignSelf: 'center',
		width: '95%',
		maxWidth: 700,
		paddingTop: 30,
		paddingBottom: 30,
	},

	matchHeader: {
		marginBottom: 60,
	},

	row: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 20,
	},

	scoresForm: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		flexGrow: 1,
	},

	scoreInput: {
		fontSize: 30,
		fontFamily: '"Black Ops One", serif',
		textAlign: 'center',
		paddingVertical: 5,
		borderRadius: 8,
		borderColor: colors.neutral(3),
		borderWidth: 1,
		color: colors.neutral(1),
		backgroundColor: colors.background(2),
		width: 100,
		outline: 'none',
	},

	deleteIcon: {
		paddingHorizontal: 10,
	},

	commentOpener: {
		layout: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			paddingVertical: 10,
			marginTop: 40,
		},

		text: {
			textAlign: 'center',
			fontSize: 16,
		},

		icon: {
			marginRight: 10,
		}
	},

	commentInput: {
		marginVertical: 30,
		marginHorizontal: 10,
		marginBottom: 0,
		borderRadius: 8,
		borderColor: colors.neutral(3),
		borderWidth: 1,
		color: colors.neutral(1),
		backgroundColor: colors.background(2),
		padding: 15,
		height: 150,
		outline: 'none',
	},

	delete: {
		color: colors.bad(1),
		fontSize: 14,
		paddingVertical: 10,
		paddingRight: 30,
		alignSelf: 'flex-end',
	},

	submit: {
		layout: {
			backgroundColor: colors.accent(3),
			padding: 20,
			alignSelf: 'center',
			width: '90%',
			maxWidth: 500,
			marginHorizontal: 30,
		},

		text: {
			color: colors.neutral(0),
			fontSize: 20,
			textAlign: 'center',
		},
	},

	submiting: {
		color: colors.accent(3),
		fontSize: 20,
		textAlign: 'center',
		padding: 20,
	},

	deleting: {
		color: colors.bad(1),
		fontSize: 20,
		textAlign: 'center',
		padding: 20,
	}
}