import React from "react";
import { ImageBackground, View } from "react-native";
import use from "rn-tools/hook";
import styles from "../../res/styles";

export default function Section({ background, foreground, children, innerStyle,  ...props }) {
	props.style = use.style(props.style, {minHeight: '100vh'});

	return (
		<ImageBackground
			source={background}
			resizeMode="cover"
			loading='lazy'
			{...props}>
			<View style={[styles.fit, { backgroundColor: foreground, zIndex: 0 }]}/>

			<View style={[{ width: '100%', maxWidth: styles.maxAppWidth, alignSelf: 'center', flexGrow: 1, zIndex: 1 }, innerStyle].flat()}>
				{children}
			</View>
		</ImageBackground>
	);
};