import moment from "moment";
import React from "react";
import { View } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import use from "rn-tools/hook";
import { strings } from "../../res";
import TeamLogo from "../TeamLogo";
import Text from "../Text";
import fr from "ipronostic.common/src/lang/fr"
import TextHolder from "../TextHolder"
import { isNil } from "ramda";


export default function TabletMatchUIHeader({ match, ...props }) {
	const { team1, team2 } = match || {};
	const time = match?.time && moment(match.time);
	props.style = use.style(props.style, localStyles.layout);
	return (
		<View {...props} >
			<TeamLogo
				source={team1?.logo}
				loading={!match}
				size={140}
				style={localStyles.team.logo}
			/>

			<View style={localStyles.body}>
				<LoadingPlaceholder
					animated={!match}
					Component={Text}
					numberOfLines={1}
					style={localStyles.team1.name}>
					{fr(team1?.name) || strings.invisible}
				</LoadingPlaceholder>

				<View>
					<Text style={localStyles.scores}>
						{match?.score1}
						{!isNil(match?.score1) && ' - '}
						{match?.score2}

						{strings.invisible}
					</Text>

					<Text style={{ textAlign: 'center' }}>
						{fr(match?.league)}
					</Text>

					<LoadingPlaceholder
						Component={Text}
						animated={!match}
						style={localStyles.date(time)}>
						{
							time?.format('DD/MM/YY') ||
							<TextHolder for="00/00/00" />
						}
					</LoadingPlaceholder>
				</View>


				<LoadingPlaceholder
					animated={!match}
					Component={Text}
					numberOfLines={1}
					style={localStyles.team2.name}>
					{fr(team2?.name) || strings.invisible}
				</LoadingPlaceholder>
			</View>

			<TeamLogo
				source={team2?.logo}
				loading={!match}
				size={140}
				style={localStyles.team.logo}
			/>
		</View>
	);
}

const localStyles = {
	layout: {
		flexDirection: 'row',
		paddingHorizontal: 15,
	},

	team: {
		name: {
			flexShrink: 1,
			fontSize: 23,
			minWidth: 100,
			borderRadius: 15,
			paddingHorizontal: 5,
		},
	},

	body: {
		justifyContent: "space-evenly",
		marginHorizontal: 15,
		flex: 1,
	},

	row: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},

	team1: {
		name: {
			alignSelf: 'flex-start',
			textAlign: 'left',
			marginRight: 10,
		},
	},

	middleRow: {
		flexGrow: 1
	},

	scores: {
		fontSize: 30,
		fontFamily: '"Black Ops One", serif',
		textAlign: 'center',
	},

	date: time => ({
		fontSize: 14,
		textAlign: 'center',
		alignSelf: 'center',
		borderRadius: 15,
		color: time?.isBefore() ? 'grey' : 'white',
	}),

	team2: {
		name: {
			alignSelf: 'flex-end',
			textAlign: 'right',
			marginLeft: 10,
		},
	},
};


Object.assign(localStyles.team1.name, localStyles.team.name);
Object.assign(localStyles.team2.name, localStyles.team.name);