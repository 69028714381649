import parallel from "js-tools/function/parallel";
import moment from "moment";
import React from "react";
import { Image, View } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import use from "rn-tools/hook";
import { colors, styles } from "../res";
import ScoreText from "./ScoreText";
import TextHolder from "./TextHolder"
import Text from "./Text";
import fr from 'ipronostic.common/src/lang/fr'
import { isNil } from "ramda";

/**
 * @param {*}
 */
export default function MatchView({ match, children, ...props }) {
	const matchDate = match && new Date(match.time);
	const sameYear = matchDate?.getFullYear() === new Date().getFullYear();

	const [level, onLayout, { select }] = use.responsive(levels);
	props.onLayout = parallel(onLayout, props.onLayout);

	props.style = use.style(props.style, localStyles.layout);


	return (
		<View {...props}>
			<View style={localStyles.team1(select)}>
				<LoadingPlaceholder
					animated={!match}
					Component={Image}
					source={match?.team1 && { uri: match.team1.logo }}
					resizeMode="contain"
					style={localStyles.teamLogo(level, match)} />

				{
					!isNil(match?.score1) &&
					<ScoreText style={localStyles.score1}>
						{match?.score1}
					</ScoreText>
				}
			</View>


			<View style={{ flex: 1 }}>
				<LoadingPlaceholder
					Component={Text}
					animated={!match}
					numberOfLines={match && 2}
					style={localStyles.time(match?.time)}>
					{
						children || (
							match ?
								<>
									{
										moment(match.time)
											.format(`DD.MM${!sameYear ? '.YY' : ''}`)
									}

								</> :
								<TextHolder for="DD/MM/YY" />
						)
					}
				</LoadingPlaceholder>

				{
					!children &&
					<Text
						numberOfLines={2}
						style={localStyles.league}>
						{
							match?.league &&
							fr(match.league)
						}
					</Text>
				}

			</View>



			<View style={localStyles.team2(select)}>

				{
					!isNil(match?.score2) &&
					<ScoreText style={localStyles.score2}>
						{match?.score2}
					</ScoreText>
				}

				<LoadingPlaceholder
					animated={!match}
					Component={Image}
					source={match?.team2 && { uri: match.team2.logo }}
					resizeMode="contain"
					style={localStyles.teamLogo(level, match)} />
			</View>
		</View>
	);
}

const levels = {
	m: 350,
	l: 440,
};


const localStyles = {
	layout: {
		flexDirection: styles.flexDirection.row,
		justifyContent: styles.justifyContent.spaceEvenly,
		alignItems: 'center',
	},

	score1: {
		margin: 20,
		marginBottom: 0,
		fontSize: 20,
	},
	score2: {
		margin: 20,
		marginBottom: 0,
		fontSize: 20,
	},

	teamLogo: (level, hasMatch) => ({
		...styles[hasMatch ? 'square' : 'circle'](level >= levels.l ? 70 : 50),
	}),

	time: (time) => {
		const pastLast24 = time && new Date(time).getTime() < (Date.now() - 24 * 60 * 60 * 1000);
		return {
			color: pastLast24 ? 'lightgrey' : 'white',
			fontWeight: pastLast24 ? 'normal' : 'bold',
			borderRadius: 5,

			textAlign: 'center',
			alignSelf: 'center',
			fontSize: 15,
			flexShrink: 1,
		};
	},

	league: {
		fontSize: 12,
		textAlign: 'center',
		marginTop: 5,
	},

	team1: select => ({
		alignItems: 'center',
		flexDirection: select({
			m: 'column',
			default: 'row',
		}),
	}),

	team2: select => ({
		alignItems: 'center',
		flexDirection: select({
			m: 'column-reverse',
			default: 'row',
		}),
	}),
};