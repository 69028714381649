import React from "react";
import { Image, View } from "react-native";
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import use from "rn-tools/hook";
import { images, colors, styles } from "../res";
import ScoreView from "./ScoreView";
import Text from "./Text";
import TextHolder from "./TextHolder";

export default function UserView({ user, ...props }) {
	const [level, onLayout] = use.responsive(levels);
	props.onLayout = onLayout;
	props.style = use.style(props.style, localStyles.layout);

	return (
		<View {...props}>
			<LoadingPlaceholder
				animated={!user}
				Component={Image}
				source={user && { uri: user.picture }}
				resizeMode="cover"
				defaultSource={user && images.user}
				style={localStyles.picture(level)}
			/>

			<View style={localStyles.infos}>
				<LoadingPlaceholder
					animated={!user}
					Component={Text}
					numberOfLines={1}
					style={localStyles.name(level)}>
					{
						user?.name ||
						<TextHolder for={10} />
					}
				</LoadingPlaceholder>
				<LoadingPlaceholder
					animated={!user}
					Component={Text}
					numberOfLines={1}
					style={localStyles.pseudo(level)}>
					{
						user ? `@${user.pseudo}`
							: <TextHolder for={14} />
					}
				</LoadingPlaceholder>
			</View>

			<ScoreView
				score={user?.score}
				style={localStyles.score(level)}
			/>
		</View>
	);
}

const levels = {
	l: 350,
};

const sizes = [ScoreView.SIZE, ScoreView.SIZE - 20];


const localStyles = {
	layout: {
		flexDirection: 'row',
		alignItems: styles.alignItems.center,
	},

	picture: level => styles.circle(level > levels.l ? sizes[0] : sizes[1]),

	infos: {
		flex: 1,
		marginHorizontal: 10,
		alignItems: 'flex-start',
	},

	name: level => ({
		fontSize: level > levels.l ? 18 : 14,
		fontWeight: 'bold',
		marginBottom: 5,
		borderRadius: 5,
	}),


	pseudo: level => ({
		color: colors.link(1),
		fontSize: level > levels.l ? 14 : 12,
		letterSpacing: 1.2,
		marginLeft: 5,
		borderRadius: 3,
	}),

	score: level => ({
		transform: level <= levels.l && [{ scale: sizes[1] / sizes[0] }],
		marginHorizontal: level <= levels.l && (- (sizes[1] * (1 - sizes[1] / sizes[0]) / 2)),
		opacity: level ? 1 : 0
	})
};
