import React from "react"
import { Image } from "react-native"
import use from "rn-tools/hook"
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder"
import { styles } from "../res";

export default function TeamLogo({ source, size, loading, ...props }) {
	props.animated = loading;
	source = use.memory(() => source && { uri: source }, [source]);

	props.style = use.style(
		props.style,
		() => ({
			...(loading ? styles.circle(size) : styles.square(size)),
			overflow: 'hidden',
		}),
		[size, loading]
	);

	return (
		<LoadingPlaceholder {...props}>
			<Image
				source={source}
				resizeMode="contain"
				style={styles.fit}
			/>
		</LoadingPlaceholder>
	);
}