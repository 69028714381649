import { gql } from "@apollo/client"
import MatchHeader from "@src/component/MatchHeader"
import MatchScores from "@src/component/MatchScores"
import Text from "@src/component/Text"
import UserHeader from "@src/component/UserHeader"
import { colors } from "@src/res"
import React from 'react'
import { Pressable, ScrollView, View } from "react-native"
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share"
import Portal from "rn-tools/component/Portal"
import use from "rn-tools/hook"
import useQueryLoader from "rn-tools/hook/useQueryLoader"
import useUser from "rn-tools/hook/useUser"
import ErrorView from "../../component/ErrorView"
import MaterialIcon from "../../component/icon/Material"
import BlankPageIcon from '../../component/icon/svg/BlankPage'
import ConfusedIcon from '../../component/icon/svg/Confused'
import { styles } from "../../res"
import screens from '../../screens'
import createAppURL from '../../utils/createAppURL'
import moment from "moment"

function PronosticUI({ route }) {
	const id = route.params?.id;
	const { data, loading, refetch } = useQueryLoader(QUERY, { id });
	const pronostic = data?.pronostic_by_pk;

	const { match, user } = pronostic || {};
	const currentUser = useUser();

	const [sharing, setSharing] = use.state(false);
	const share = () => setSharing(true);
	const shareURL = createAppURL(screens.pronostic, { id });

	const isOwner = currentUser && user?.firebaseId === currentUser.uid;
	const editable = isOwner && new Date() < new Date(match?.time);
	const matchMoment = match && moment(match.time);
	const syncTime = matchMoment?.clone().add(1, 'day').hours(0).minutes(0).seconds(0);

	return (
		(pronostic || loading) ?
			<ScrollView
				contentContainerStyle={localStyles.content}
				style={localStyles.layout}>
				<View style={localStyles.headerRow(pronostic)}>
					<Text style={localStyles.title}>
						Sur le match
					</Text>
				</View>

				<Portal to={screens.match} with={{ id: match?.id }} disabled={!match}>
					<MatchHeader
						match={match}
						style={[localStyles.card, { marginBottom: 40 }]}
					/>
				</Portal>

				<Portal to={screens.user} with={{ id: user?.id }} disabled={!user}>
					<UserHeader
						user={user}
						style={localStyles.user}
					/>
				</Portal>

				<View style={localStyles.headerRow(pronostic)}>
					<Text style={localStyles.title}>
						a pronostiqué
					</Text>

					<View style={localStyles.actions}>
						{
							!sharing ?

								<Pressable
									onPress={share}
									style={localStyles.action}>
									<MaterialIcon name="share" size={25} color="grey" />
								</Pressable> :

								<>
									<View style={localStyles.action}>
										<TwitterShareButton
											url={shareURL}
											style={localStyles.action}>
											<TwitterIcon size={24} round />
										</TwitterShareButton>
									</View>

									<View style={localStyles.action}>
										<FacebookShareButton
											url={shareURL}
											style={localStyles.action}>
											<FacebookIcon size={24} round />
										</FacebookShareButton>
									</View>
								</>
						}

						{
							editable &&
							<Portal to={screens.editPronostic} with={{ matchId: pronostic.match.id }}>
								<View style={localStyles.action}>
									<MaterialIcon name="edit" size={25} color="grey" />
								</View>
							</Portal>
						}
					</View>
				</View>

				<Portal
					to={screens.editPronostic}
					with={{ matchId: pronostic?.match.id }}
					disabled={!editable}>
					<View style={[localStyles.card, { padding: 30 }]}>
						<MatchScores
							scores={pronostic}
							style={localStyles.scores} />

						{
							Boolean(pronostic?.comment) &&
							<Text style={localStyles.comment}>
								{pronostic?.comment}
							</Text>
						}
					</View>
				</Portal>

				<Text style={{
					color: 'grey',
					fontStyle: 'italic',
					textAlign: 'center',
					marginTop: 20,
					marginHorizontal: 15,
				}}>
					{
						isOwner && editable &&
						`Vous pouvez modifier votre pronostique jusqu'à ${matchMoment.isSame(moment(), 'day') ? matchMoment.format('HH:mm') : matchMoment.calendar()}.\n`
					}

					{
						isOwner && syncTime?.isAfter() &&
						`Votre score sera mis à jour le soir du match à minuit.`
					}
				</Text>

			</ScrollView> :

			data ?
				<View style={{ alignSelf: 'center', alignItems: 'center', marginTop: 100 }}>
					<BlankPageIcon size={200} />
					<ConfusedIcon
						size={150}
						style={{ position: 'absolute', top: 70, right: -10 }} />

					<Text style={{ textAlign: 'center', marginTop: 60, fontWeight: 'bold', fontSize: 20 }}>
						{`Ce pronostic ne semble pas exister`}
					</Text>

					<Portal to={screens.matchList}>
						<Text style={{ textAlign: 'center', color: colors.accent(), paddingVertical: 20 }}>
							{`Retour à l'accueil →`}
						</Text>
					</Portal>
				</View> :

				<ErrorView
					retry={refetch}
					style={localStyles.error} />
	);
}

export default React.memo(PronosticUI);

const QUERY = gql`query PronosticUI ($id: Int! = -1) {
	pronostic_by_pk(id: $id){
		id
		score1
		score2
		comment

		match {
			id
			time
			score1
			score2
			league

			team1 {
				logo
				name
			}
	
			team2 {
				logo
				name
			}
		}

		user {
			id
			firebaseId
			name
			pseudo
			picture
			score
			numberOfPronostics
		}
	}
}`;



const localStyles = {
	layout: {
		flex: 1,
	},

	content: {
		alignSelf: 'center',
		width: '95%',
		maxWidth: 700,
		paddingTop: 30,
		paddingBottom: 60,
		flexGrow: 1,
	},

	headerRow: visible => ({
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		opacity: visible ? 1 : 0,
		paddingBottom: 20,
	}),

	actions: {
		flexDirection: 'row',
		alignItems: 'center',
	},

	action: {
		...styles.center,
		paddingHorizontal: 10,
	},

	title: ({
		fontWeight: 'bold',
		fontSize: 20,
		letterSpacing: 1.5,
		marginLeft: 20,
	}),

	user: {
		marginHorizontal: 15,
		marginBottom: 60,
	},

	card: {
		padding: 15,
		marginHorizontal: 10,
		borderRadius: 10,
		backgroundColor: colors.background(1),
		borderColor: colors.border(),
		borderWidth: 1,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.25,
		shadowRadius: 5,
	},

	scores: {
		textAlign: 'center',
		alignSelf: 'center',
		letterSpacing: 10,
	},

	comment: {
		lineHeight: 22,
		marginTop: 20,
	},

	error: {
		flex: 1,
	}
}