import React from 'react';

export default function ConfusedIcon({size, ...props}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} width={size} height={size} {...props}>
			<g>
				<path style={{fill:'#BCD151'}} d="M201.74,389.071h18.143C252.478,347.272,374.259,372.07,334.59,512H87.032   C47.363,372.07,169.144,347.272,201.74,389.071z" />
				<path style={{fill:'#8D9F25'}} d="M245.219,373.107c39.536,10.129,76.43,52.638,51.977,138.893h37.394   C366.996,397.692,291.662,360.213,245.219,373.107z" />
				<path style={{fill:'#F6BB93'}} d="M210.811,420.408L210.811,420.408c20.652,0,37.394-16.742,37.394-37.394v-46.282h-74.788v46.282   C173.417,403.666,190.159,420.408,210.811,420.408z" />
				<path style={{fill:'#E49775'}} d="M173.417,373.628c23.266,5.311,49.784,6.73,74.788,4.256v-41.151h-74.788V373.628z" />
				<g>
					<path style={{fill:'#F6BB93'}} d="M82.297,260.108h41.787v-63.193H82.297c-17.45,0-31.596,14.146-31.596,31.596v0    C50.701,245.962,64.847,260.108,82.297,260.108z" />
					<path style={{fill:'#F6BB93'}} d="M339.325,260.108h-41.787v-63.193h41.787c17.45,0,31.596,14.146,31.596,31.596v0    C370.922,245.962,356.775,260.108,339.325,260.108z" />
				</g>
				<path style={{fill:'#52668D'}} d="M98.356,247.588c-7.792-33.731-19.307-78.69-24.498-100.62   c-33.375-28.978-40.28-90.227-20.1-133.762c2.909-6.276,10.926-8.149,16.294-3.787c13.263,10.777,39.427,20.997,88.337,3.057   c95.249-34.936,175.458,12.1,180.062,36.183c5.15,0,30.163,3.544,30.163,28.141c0,20.922-34.384,132.353-49.209,170.789H98.356z" />
				<path style={{fill:'#243B5D'}} d="M338.451,48.658C334.833,29.732,284.52-3.37,216.91,0.279c51.026,3.103,87.437,31.427,90.336,48.38   c4.607,0,26.983,3.544,26.983,28.141c0,20.922-30.759,132.353-44.022,170.789h29.197c14.825-38.436,49.209-149.867,49.209-170.789   C368.614,52.202,343.601,48.658,338.451,48.658z" />
				<path style={{fill:'#F6BB93'}} d="M92.978,290.193c0,83.216,235.667,83.216,235.667,0c0-47.683-6.377-128.417-20.272-159.8   c-3.299-7.451-10.472-15.096-16.343-18.605c-4.966-2.968-10.174-4.646-15.471-4.646c-5.926,0-11.708,1.898-17.513,4.646   c-15.041,7.107-30.214,19.863-48.235,19.863s-33.194-12.757-48.235-19.863c-5.804-2.748-11.587-4.646-17.513-4.646   c-5.297,0-10.505,1.677-15.471,4.646c-5.882,3.509-13.064,11.167-16.376,18.638C99.378,161.637,92.978,241.606,92.978,290.193z" />
				<path style={{fill:'#E49775'}} d="M308.373,130.393c-3.299-7.451-10.472-15.096-16.343-18.605c-4.966-2.968-10.174-4.646-15.471-4.646   c-4.309,0-8.543,1.012-12.764,2.616c1.148,0.587,2.284,1.263,3.406,2.029c5.138,3.509,11.417,11.154,14.304,18.605   c12.161,31.383,17.743,112.117,17.743,159.8c0,39.762-47.093,60.523-96.275,62.287c61.41,1.928,125.672-18.833,125.672-62.287   C328.645,242.511,322.267,161.777,308.373,130.393z" />
				<g>
					<path style={{fill:'#243B5D'}} d="M267.094,231.592c-4.268,0-7.726-3.459-7.726-7.726v-12.021c0-4.268,3.459-7.726,7.726-7.726    s7.726,3.459,7.726,7.726v12.021C274.82,228.133,271.361,231.592,267.094,231.592z" />
					<path style={{fill:'#243B5D'}} d="M154.529,231.592c-4.267,0-7.726-3.459-7.726-7.726v-12.021c0-4.268,3.459-7.726,7.726-7.726    s7.726,3.459,7.726,7.726v12.021C162.256,228.133,158.796,231.592,154.529,231.592z" />
				</g>
				<path style={{fill:'#243B5D'}} d="M266.451,196.591c-8.576,0-17.152-2.425-23.835-7.275c-3.454-2.507-4.221-7.338-1.715-10.792   c2.506-3.455,7.338-4.222,10.791-1.715c8,5.807,21.517,5.807,29.517,0c3.452-2.505,8.284-1.739,10.792,1.715   c2.506,3.454,1.738,8.285-1.715,10.792C283.603,194.166,275.027,196.591,266.451,196.591z" />
				<path style={{fill:'#243B5D'}} d="M174.462,196.591c-1.573,0-3.161-0.48-4.532-1.474c-8-5.807-21.518-5.806-29.518,0   c-3.455,2.504-8.286,1.737-10.791-1.715c-2.507-3.454-1.738-8.285,1.715-10.792c13.366-9.698,34.304-9.698,47.671,0   c3.453,2.507,4.221,7.338,1.715,10.792C179.21,195.484,176.853,196.59,174.462,196.591z" />
				<path style={{fill:'#7CCCF2'}} d="M403.787,11.486c-38.51-3.875-73.778,23.514-79.515,61.79c-5.9,39.368,20.609,75.6,58.867,82.683   c-2.937,7.228-7.427,13.756-13.147,19.084c-2.703,2.518-0.761,7.045,2.924,6.786c2.376-0.166,4.768-0.493,7.165-0.99   c15.784-3.272,28.474-13.201,35.778-26.241c27.861-7.668,49.669-31.556,53.2-62.024C473.746,52.128,444.444,15.577,403.787,11.486z   " />
				<path style={{fill:'#4FACDB'}} d="M403.787,11.486c-4.812-0.484-9.573-0.48-14.232-0.028c0.101,0.012,0.202,0.015,0.303,0.028   c33.608,4.091,57.829,40.642,53.955,81.088c-2.919,30.468-20.946,54.356-43.976,62.024c-6.038,13.04-16.527,22.969-29.575,26.241   c-0.064,0.016-0.129,0.029-0.193,0.044c0.722,0.642,1.7,1.027,2.847,0.946c2.376-0.166,4.768-0.493,7.165-0.99   c15.784-3.272,28.474-13.201,35.778-26.241c27.861-7.668,49.669-31.556,53.2-62.024C473.746,52.128,444.444,15.577,403.787,11.486z   " />
				<path style={{fill:'#8D9F25'}} d="M112.119,512v-38.286c0-4.268,3.459-7.726,7.726-7.726s7.726,3.459,7.726,7.726V512H112.119z    M309.505,512v-38.286c0-4.268-3.459-7.726-7.726-7.726c-4.268,0-7.726,3.459-7.726,7.726V512H309.505z" />
				<path style={{fill:'#828C1F'}} d="M309.505,473.714c0-3.147-1.884-5.85-4.584-7.054c-0.554,13.525-2.98,28.605-7.725,45.34h12.308   V473.714z" />
				<g>
					<path style={{fill:'#243B5D'}} d="M392.642,131.52c-4.268,0-7.726-3.459-7.726-7.726v-1.03c0-4.268,3.459-7.726,7.726-7.726    s7.726,3.459,7.726,7.726v1.03C400.368,128.061,396.909,131.52,392.642,131.52z" />
					<path style={{fill:'#243B5D'}} d="M392.642,109.866c-4.268,0-7.726-3.459-7.726-7.726V88.516c0-6.546,5.326-11.872,11.872-11.872    h2.88c3.463,0,6.677-1.39,9.05-3.915c2.37-2.522,3.556-5.827,3.34-9.306c-0.38-6.117-5.468-11.204-11.583-11.585    c-5.579-0.356-10.749,3.093-12.555,8.361c-1.384,4.037-5.784,6.186-9.815,4.803c-4.036-1.384-6.187-5.779-4.803-9.815    c4.057-11.83,15.633-19.567,28.131-18.772c13.982,0.868,25.18,12.066,26.048,26.049c0.484,7.78-2.181,15.183-7.502,20.846    c-5.088,5.412-12.201,8.589-19.611,8.777v10.052C400.368,106.407,396.909,109.866,392.642,109.866z" />
				</g>
				<path style={{fill:'#243B5D'}} d="M260.693,294.632c-1.977,0-3.955-0.755-5.463-2.263c-8.49-8.49-22.305-8.492-30.796,0   c-3.018,3.016-7.91,3.017-10.927-0.001c-3.018-3.017-3.018-7.909,0-10.927c14.516-14.515,38.135-14.514,52.649,0   c3.018,3.017,3.018,7.909,0.001,10.927C264.648,293.877,262.67,294.632,260.693,294.632z" />
			</g>

		</svg>
	);
}