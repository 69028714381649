import React from 'react'
import use from "rn-tools/hook"
import Text from "./Text"

export default function ScoreText({ ...props }) {
	props.style = use.style(props.style, localStyles.layout);

	return <Text {...props}/>;
}

const localStyles = {
	layout: {
		fontSize: 30,
		fontFamily: '"Black Ops One", serif',
	},
};