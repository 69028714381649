import { gql } from "@apollo/client";
import { getApp } from "firebase/app";
import { updateProfile } from "firebase/auth";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import React, { useEffect } from "react";
import { Image, TextStyle, TouchableOpacity, View, ViewStyle, ImageStyle, ViewProps } from "react-native";
import { toast } from 'react-toastify';
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder";
import { Nilable } from "../../@types/utils";
import apollo from "../../App/apollo";
import { User } from "../../generated/graphql";
import { strings, styles, images } from "../../res";
import selectFiles, { MimeType } from "../../utils/selectFiles";
import MaterialIcon from "../icon/Material";
import ScoreView from "../ScoreView";
import Text from "../Text";
import NameAndPseudo, { NameAndPseudoProps } from "./NameAndPseudo";
import useUser from "rn-tools/hook/useUser";

const Hoverable = require("rn-tools/component/Hoverable").default;
const use = require("rn-tools/hook").default;


function UserHeader({ user, editable, startEdit, ...props }: UserHeaderProps) {
	const currentUser = useUser();
	const isCurrentUser = currentUser && (user?.firebaseId === currentUser.uid);

	const [level, onLayout] = use.responsive(levels);
	const desktop = level > levels.mobile;

	const loading = !user;
	editable = Boolean(editable && !loading && user && isCurrentUser);
	const nameAndPseudo = (
		<NameAndPseudo
			user={user}
			editable={editable}
			startEdit={startEdit as NameAndPseudoProps['startEdit']}
			textsStyle={localStyles.userTexts(desktop)}
			style={localStyles.user(desktop)}
		/>
	);

	const [editingPicture, setEditingPicture] = use.state(false);
	let editProfilePicture = !editable ? undefined :
		async function () {
			const file = await selectFiles(MimeType.image);
			if (file && currentUser) {
				if (file.size > 1000 * 1000) {
					toast.dark("❌ Taille maximale: 1 MB");
					return;
				}

				setEditingPicture(true);
				try {
					const storage = getStorage(getApp());
					const destination = ref(storage, `/user/profile/picture/${currentUser.uid}`);
					await uploadBytes(destination, file);
					const photoURL = await getDownloadURL(destination);
					await updateProfile(currentUser, { photoURL });
					// update profile picture on database
					const gqlResponse = await apollo.mutate({
						mutation: gql`mutation updateProfilePicture($id: String!, $photoURL: String) {
					update_user_by_pk(pk_columns: {id: $id}, _set: {picture: $photoURL}) {
						id
						picture
					}
				}`,
						variables: { id: user?.id, photoURL },
					});

					if (gqlResponse.errors)
						console.error(...gqlResponse.errors);

					toast(`Photo de profile mise a jour!`);
				}
				catch (error) {
					console.error(error);
					toast(`Une erreur s'est produite;`);
				}
				finally {
					setEditingPicture(false);
				}
			}
		};

	const shouldStartEditPicture = startEdit === "picture";
	useEffect(() => {
		if (shouldStartEditPicture)
			editProfilePicture?.();
	}, [shouldStartEditPicture, Boolean(editProfilePicture)]);

	props.style = use.style(props.style, localStyles.layout);

	return (
		<View onLayout={onLayout} {...props}>
			{
				Boolean(level) &&
				<>
					<View style={localStyles.content(desktop)}>
						<Hoverable disabled={!editable}>
							{
								(hovered: boolean) =>
									<LoadingPlaceholder
										animated={loading || editingPicture}
										style={localStyles.picture(loading, desktop)}>
										{
											(!editingPicture && user) && (
												<>
													<Image
														source={{ uri: user.picture || undefined }}
														resizeMode="cover"
														defaultSource={images.user}
														style={styles.fit}
													/>

													{
														hovered &&
														<TouchableOpacity
															onPress={editProfilePicture}
															style={localStyles.pictureEditor}>
															<MaterialIcon name="edit" size={20} color="white" />
														</TouchableOpacity>
													}
												</>
											)
										}
									</LoadingPlaceholder>
							}
						</Hoverable>

						{
							desktop &&
							nameAndPseudo
						}


						<View style={localStyles.stats}>

							<LoadingPlaceholder
								animated={loading}
								style={localStyles.placeholder}>
								<Text style={localStyles.numberOfPronostics}>
									{user && `${user?.numberOfPronostics} pro`}
									{
										(user?.numberOfPronostics || 0 > 1) &&
										's'
									}
									{user && '.'}
									{strings.invisible}
								</Text>
							</LoadingPlaceholder>
						</View>


						<ScoreView
							score={user?.score}
							style={localStyles.score} />
					</View>

					{
						!desktop &&
						nameAndPseudo
					}
				</>
			}
		</View>
	);
}

export default React.memo(UserHeader);

interface UserHeaderProps extends ViewProps {
	user: Nilable<User>,
	editable?: boolean,
	startEdit?: NameAndPseudoProps['startEdit'] | 'picture'
}

const levels = { mobile: 580 };

const localStyles = {
	layout: {
	},

	content(desktop: boolean): ViewStyle {
		return {
			flexDirection: "row",
			alignItems: desktop ? "flex-end" : "center",
			justifyContent: "space-between",
		};
	},

	picture(loading: boolean, desktop: boolean): ViewStyle {
		return {
			borderWidth: loading ? 0 : 3,
			borderColor: "white",
			...styles.circle(desktop ? 100 : 75),
			alignSelf: "center",
			overflow: "hidden",
			marginRight: 15,
		};
	},

	pictureEditor: {
		...styles.fit,
		backgroundColor: '#00000055',
		...styles.center,
	} as ViewStyle,

	user(desktop: boolean): ViewStyle {
		return {
			flex: desktop ? 1 : undefined,
			alignItems: desktop ? 'flex-start' : "center",
			overflow: "hidden",
			marginTop: 10,
		};
	},

	userTexts(desktop: any): TextStyle {
		return {
			textAlign: desktop ? 'left' : 'center',
		};
	},

	stats: {
		alignItems: "flex-end",
	} as ViewStyle,

	numberOfPronostics: {
		textAlign: styles.textAlign.right,
		minWidth: 50,
		fontSize: 20,
	},

	score: {
		marginLeft: 10,
	},

	placeholder: {
		borderRadius: 10,
	}
};