import React from "react";
import { Image } from "react-native";

const IPronostic = ({...props}) => {
	return (
		<Image  
			source="/logo.svg"
			resizeMode="contain"
			{...props}
		/>
	);
}

export default IPronostic;