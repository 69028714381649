import React from 'react'
import LoadingPlaceholder from "rn-tools/component/LoadingPlaceholder"
import use from "rn-tools/hook"
import ScoreText from './ScoreText'
import TextHolder from "./TextHolder"

export default function MatchScores({ scores, ...props }) {
	props.style = use.style(props.style, localStyles.layout);

	return (
		<LoadingPlaceholder
			Component={ScoreText}
			animated={!scores}
			{...props}>
			{
				scores ? `${scores.score1} - ${scores.score2}` :
					<TextHolder for='0 - 0' />
			}
		</LoadingPlaceholder>
	);
}

const localStyles = {
	layout: {
		borderRadius: 5,
	},
};