import React, { useContext } from "react";
import { Image, View } from "react-native";
import use from "rn-tools/hook";
import Text from '../../../component/Text';
import { styles } from "../../../res";
import HomeContext from "../context";
import Section from "../Section";

function ProfileSection({ ...props }) {
	const [, onLayout, { select }] = use.responsive(levels);
	props.onLayout = onLayout;

	const context = useContext(HomeContext);

	return (
		<Section
			background={require('./background.jpeg')}
			foreground="#00000030"
			innerStyle={select({
				default: {
					flexDirection: 'row',
					alignItems: 'center',
					flexGrow: undefined,
					paddingBottom: 20,
				},

				mobile: {
					alignItems: 'center',
					justifyContent: 'space-evenly',
				}
			})}
			style={select({
				Infinity: {
					justifyContent: 'flex-end'
				},
			})}
			{...props}>
			<Image
				source={require('./demo.png')}
				resizeMode="contain"
				loading='lazy'
				style={[
					styles.square(300)
				]} />

			<View style={[
				select({
					default: { flex: 1, marginLeft: 30, marginRight: 20 },
					mobile: {
						marginHorizontal: 20,
					}
				})
			]}>
				<Text style={[
					context.title,
					select({ mobile: { textAlign: 'center' } }),
					{ marginBottom: 20 },
				]}>
					{`Faites grimper votre score`}
				</Text>

				<Text
					style={[
						context.content,
						select({ mobile: { textAlign: 'center' } }),
						{ marginBottom: 50 },
					]}>
					{`Une fois un match fini, vous collectez des points selon votre pronostique du match. Les points collectés s'ajoutent au score de votre profile.`}
				</Text>
			</View>


		</Section>
	);
}

export default React.memo(ProfileSection);

const levels = {
	mobile: 770
};