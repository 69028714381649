import createColorsRes from 'rn-tools/res/createColorsRes';

const rawColors = {
	background: ['#0a0a0A', 'rgb(21,23,24)', '#242829'],
	border: ['rgb(60, 60, 60)'],
	primary: ['black'],	
	accent: ['#00FF7F', '#02d96d', '#9cffcd', '#00b55b'],
	bad: ['red', '#f55'],
	neutral: ['white', '#f9f9f9', 'lightgrey', 'grey'],
	link: ['blue', '#03A9F4']
};

export default createColorsRes(rawColors);